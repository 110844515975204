import { createSlice } from '@reduxjs/toolkit';
import { createSvg } from '~/units/svgBuilder';
import {
  randomColor,
  duplicateShape,
  createLine,
  createRectangle,
  createImagePicker,
  createGradientRect,
  createCircle,
  createArc,
  createText,
  createImage,
  createIcon,
  createGroup,
  createMask,
  fromJson
} from '~/units/shapeBuilder';
import Group from '~/units/Group';
import { List } from 'immutable';

// ----------------------------------------------------------------------

const initialState = {
  name: '',
  model: 'Versa',
  design: createSvg('Versa'),
  themes: [['#ffffff']],
  svgorig: {},
  hoveredElement: null,
  selectedElement: null
};

const applyRecursively = (group, uid, f) => {
  if (group.uid === uid) {
    return group.update(f);
  }
  if (group.children) {
    let newChildren = List([]);
    const itemIndex = group.children.findIndex(e => e.uid === uid);
    if (itemIndex > -1) {
      newChildren = group.children.update(itemIndex, f);
    } else {
      for (let i = 0; i < group.children.size; i++) {
        newChildren = newChildren.push(
          applyRecursively(group.children.get(i), uid, f)
        );
      }
    }
    group = group.update(g =>
      g.set(
        'children',
        newChildren.filter(item => item !== undefined)
      )
    );
  }
  if (group.mask) {
    group = group.set('mask', applyRecursively(group.mask, uid, f));
  }
  return group;
};

const _resetSvg = state => {
  state.name = '';
  state.model = 'Versa';
  state.design = createSvg('Versa');
  state.themes = [['#ffffff']];
  state.svgorig = {};
  state.hoveredElement = null;
  state.selectedElement = null;
};

const _loadSvg = (state, { payload }) => {
  const { name, model, data } = payload;
  state.name = name;
  state.model = model;
  state.svgorig = data;
  let design = createSvg(model);
  if (data) {
    design = design.load(data);
    if (data.theme) {
      const color = data.theme.color;
      const colors = data.theme.colors.filter(value => {
        return value !== color;
      });
      colors.unshift(color);
      state.themes = colors.map(c => [c]);
    } else if (data.themes) {
      state.themes = data.themes;
    } else {
      state.themes = [['#ffffff']];
    }
  }
  state.design = design;
  state.selectedElement = undefined;
  state.hoveredElement = undefined;
};

const _updateOriginalSvg = state => {
  const svgorig = state.design.save();
  svgorig.themes = state.themes;
  state.svgorig = svgorig;
};

const _addElement = (state, { payload }) => {
  const { tool, startPoint, data } = payload;
  let createdShape;
  switch (tool) {
    case 'line':
      createdShape = createLine(
        startPoint.x - 50,
        startPoint.y - 50,
        startPoint.x + 50,
        startPoint.y + 50
      );
      break;
    case 'rectangle':
      createdShape = createRectangle(
        startPoint.x - 100,
        startPoint.y - 100,
        200,
        200
      );
      break;
    case 'imagepicker':
      createdShape = createImagePicker(
        startPoint.x - 100,
        startPoint.y - 100,
        200,
        200
      );
      break;
    case 'gradientrect':
      createdShape = createGradientRect(
        startPoint.x - 100,
        startPoint.y - 100,
        200,
        200
      );
      break;
    case 'circle':
      createdShape = createCircle(startPoint.x, startPoint.y, 100);
      break;
    case 'arc':
      createdShape = createArc(startPoint.x, startPoint.y, 100);
      break;
    case 'text':
      createdShape = createText(
        startPoint.x,
        startPoint.y,
        undefined,
        (data && data.fontFamily) || undefined
      );
      break;
    case 'image':
      createdShape = createImage(
        startPoint.x,
        startPoint.y,
        data ? data.width : 200,
        data ? data.height : 200,
        data ? data.path : 'https://picsum.photos/200/200'
      );
      break;
    case 'stdicons':
      createdShape = createIcon(
        startPoint.x,
        startPoint.y,
        data.width,
        data.height,
        data.path
      );
      break;
    case 'group':
      createdShape = createGroup(startPoint.x, startPoint.y);
      break;
    case 'clipboard':
      _pasteFromClipboard(state);
      break;
    default:
  }

  if (createdShape) {
    state.design = state.design.addChild(createdShape);
    state.selectedElement = getElement(state.design, createdShape.uid);
  }
};

const _moveAndResizeElement = (state, { payload }) => {
  const { uid, position } = payload;
  state.design = applyRecursively(state.design, uid, item =>
    item.moveAndResize(position)
  );
  if (state.selectedElement && state.selectedElement.uid === uid) {
    state.selectedElement = getElement(state.design, uid);
  }
};

const _changeElementProperty = (state, { payload }) => {
  const { uid, name, value } = payload;
  state.design = applyRecursively(state.design, uid, item =>
    item.set(name, value)
  );
  if (state.selectedElement && state.selectedElement.uid === uid) {
    state.selectedElement = getElement(state.design, uid);
  }
};

const _addResource = (state, { payload }) => {
  const { name, value } = payload;
  state.design = state.design.addResource(name, value);
};

function getElement(group, uid) {
  if (group.uid === uid) {
    return group;
  }
  let element;
  for (let i = 0; i < group.children.size && !element; i++) {
    const child = group.children.get(i);
    if (child.uid === uid) {
      element = child;
    } else if (child instanceof Group) {
      element = getElement(child, uid);
      if (element === undefined && child.mask) {
        element = getElement(child.mask, uid);
      }
    }
  }
  return element;
}

function moveElement(group, targetParent, targetindex, element) {
  let newGroup = group;
  const itemIndex = newGroup.children.indexOf(element);

  if (itemIndex > -1) {
    newGroup = newGroup.removeChild(element);
  }

  let newChildren = List([]);

  if (newGroup.uid === targetParent) {
    newChildren = newGroup.children.insert(
      newGroup.children.size - targetindex,
      element
    );
    newGroup = newGroup.update(newGroup =>
      newGroup.set('children', newChildren)
    );
    newChildren = List([]);
  }

  for (let i = 0; i < newGroup.children.size; i++) {
    const child = newGroup.children.get(i);
    if (child instanceof Group) {
      newChildren = newChildren.push(
        moveElement(child, targetParent, targetindex, element)
      );
    } else {
      newChildren = newChildren.push(child);
    }
  }

  newGroup = newGroup.update(newGroup => newGroup.set('children', newChildren));

  if (newGroup.mask) {
    const mask = moveElement(newGroup.mask, targetParent, targetindex, element);
    newGroup = newGroup.update(newGroup => newGroup.set('mask', mask));
  }

  return newGroup;
}

const _changeElementPosition = (state, { payload }) => {
  const { parent, uid, newIndex } = payload;
  const element = getElement(state.design, uid);
  const newdesign = moveElement(
    state.design,
    parent === '' ? state.design.uid : parent,
    newIndex,
    element
  );
  state.design = newdesign;
};

const _addMask = (state, { payload }) => {
  const { uid } = payload;
  const mask = createMask();
  const newdesign = applyRecursively(state.design, uid, item =>
    item.set('mask', mask)
  );
  state.design = newdesign;
};

const _deleteElement = (state, { payload }) => {
  const { uid } = payload;
  const newdesign = applyRecursively(state.design, uid, item => undefined);
  state.design = newdesign;
  if (state.selectedElement && state.selectedElement.uid === uid) {
    state.selectedElement = undefined;
  }
  if (state.hoveredElement && state.hoveredElement.uid === uid) {
    state.hoveredElement = undefined;
  }
};

function _duplicateElement(state, { payload }) {
  const { uid } = payload;
  const element = getElement(state.design, uid);
  const copyElement = duplicateShape(element);
  const newdesign = state.design.addChild(copyElement);
  state.design = newdesign;
  state.selectedElement = getElement(state.design, copyElement.uid);
}

function _addTheme(state) {
  state.themes = [...state.themes, [randomColor()]];
}

function _removeTheme(state, { payload }) {
  const { idTheme } = payload;
  const themes = [...state.themes];
  if (themes.length === 1 && themes[0].length === 1) {
    return;
  }
  if (themes[idTheme].length === 1) {
    themes.splice(idTheme, 1);
  } else {
    themes[idTheme].pop();
  }
  state.themes = themes;
}

function _changeThemeColor(state, { payload }) {
  console.log(payload);
  const { idTheme, idColor, color } = payload;
  const themes = [...state.themes];
  console.log(idTheme + ' ' + themes.length + ' ' + (themes.length > idTheme));
  if (themes.length > idTheme) {
    if (themes[idTheme].length > idColor) {
      themes[idTheme][idColor] = color;
    } else {
      themes[idTheme].push(color);
    }
  } else {
    themes.push([color]);
  }
  state.themes = themes;
}

function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

function _moveTheme(state, { payload }) {
  const { oldIndex, newIndex } = payload;
  const themes = [...state.themes];
  if (oldIndex !== newIndex && newIndex < themes.length) {
    arraymove(themes, oldIndex, newIndex);
  }
  state.themes = themes;
}

function _moveColorInTheme(state, { payload }) {
  const { idTheme, oldIndex, newIndex } = payload;
  console.log(payload);
  const themes = [...state.themes];
  if (oldIndex !== newIndex && newIndex < themes[idTheme].length) {
    arraymove(themes[idTheme], oldIndex, newIndex);
  }
  state.themes = themes;
}

const _copyToClipboard = (state, { payload }) => {
  const { uid } = payload;
  const element = getElement(state.design, uid);
  const clipboard = { ...element.save(), type: element.type };
  // const clipboard = serializeImmutable(data)
  localStorage.setItem('clipboard', JSON.stringify(clipboard));
};

const _pasteFromClipboard = state => {
  const clipboard = localStorage.getItem('clipboard');
  if (clipboard) {
    const content = JSON.parse(clipboard);
    let copyElement = fromJson(content);
    copyElement = duplicateShape(copyElement);
    const newdesign = state.design.addChild(copyElement);
    state.design = newdesign;
    state.selectedElement = getElement(state.design, copyElement.uid);
  }
};

const _translateElement = (state, { payload }) => {
  const { uid, x, y } = payload;
  state.design = applyRecursively(state.design, uid, item =>
    item.translate(x, y)
  );
  if (state.selectedElement && state.selectedElement.uid === uid) {
    state.selectedElement = getElement(state.design, uid);
  }
};

const slice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    addElement: _addElement,
    resetSvg: _resetSvg,
    loadSvg: _loadSvg,
    updateOriginalSvg: _updateOriginalSvg,
    moveAndResizeElement: _moveAndResizeElement,
    changeElementProperty: _changeElementProperty,
    addResource: _addResource,
    changeElementPosition: _changeElementPosition,
    deleteElement: _deleteElement,
    addMask: _addMask,
    duplicateElement: _duplicateElement,
    setHoveredElement: (state, { payload }) => {
      state.hoveredElement = payload.uid
        ? getElement(state.design, payload.uid)
        : undefined;
    },
    setSelectedElement: (state, { payload }) => {
      state.selectedElement = payload.uid
        ? getElement(state.design, payload.uid)
        : undefined;
    },
    addTheme: _addTheme,
    removeTheme: _removeTheme,
    changeThemeColor: _changeThemeColor,
    moveTheme: _moveTheme,
    moveColorInTheme: _moveColorInTheme,
    copyToClipboard: _copyToClipboard,
    pasteFromClipboard: _pasteFromClipboard,
    changeName: (state, { payload }) => {
      state.name = payload;
    },
    translateElement: _translateElement
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetSvg,
  loadSvg,
  updateOriginalSvg,
  addElement,
  moveAndResizeElement,
  changeElementProperty,
  addResource,
  changeElementPosition,
  setHoveredElement,
  setSelectedElement,
  deleteElement,
  addMask,
  duplicateElement,
  addTheme,
  removeTheme,
  changeThemeColor,
  moveTheme,
  moveColorInTheme,
  copyToClipboard,
  pasteFromClipboard,
  changeName,
  translateElement
} = slice.actions;
