import { parse } from 'opentype.js';

export const clampedValue = (value, min, max) => {
  return Math.max(min, Math.min(max, Number(value)));
};

const isSupportedImage = file => {
  if (file.type && file.type !== '') {
    return ['image/png', 'image/jpeg'].indexOf(file.type) > -1;
  } else {
    const filename = file.name.toLowerCase();
    return (
      filename.endsWith('.png') ||
      filename.endsWith('.jpg') ||
      filename.endsWith('.jpeg')
    );
  }
};

const isSupportedFont = file => {
  if (file.type && file.type !== '') {
    return (
      [
        'font/ttf',
        'application/x-font-ttf',
        'application/vnd.oasis.opendocument.formula-template'
      ].indexOf(file.type) > -1
    );
  } else {
    const filename = file.name.toLowerCase();
    return filename.endsWith('.ttf') || filename.endsWith('.otf');
  }
};

export const ERRORS = {
  ERROR_UNSUPPORTED_FILE: 0,
  ERROR_IMAGE_SIZE_TOO_BIG: 1,
  ERROR_IMAGE_RES_TOO_BIG: 2,
  ERROR_UNSUPPORTED_FONT: 3
};

export const loadFile = f => {
  return new Promise((resolve, reject) => {
    if (isSupportedImage(f)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (f.size > 500000) {
          reject(ERRORS.ERROR_IMAGE_SIZE_TOO_BIG);
        } else {
          const image = new Image();
          image.onload = () => {
            if (image.height > 400 || image.width > 400) {
              reject(ERRORS.ERROR_IMAGE_RES_TOO_BIG);
            } else {
              resolve({ image });
            }
          };
          image.src = reader.result;
        }
      };
      reader.readAsDataURL(f);
    } else if (isSupportedFont(f)) {
      const reader = new FileReader();
      reader.onload = e => {
        try {
          const font = parse(e.target.result);
          const fullName = font.tables.name.fullName;
          const name = fullName[Object.keys(fullName)[0]];
          const data = btoa(
            new Uint8Array(e.target.result).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          resolve({ font: { name, data } });
        } catch (e) {
          console.error(e);
          reject(ERRORS.ERROR_UNSUPPORTED_FONT);
        }
      };
      reader.readAsArrayBuffer(f);
    } else {
      reject(ERRORS.ERROR_UNSUPPORTED_FILE);
    }
  });
};

export const getElementColor = (colortype, fixcolor, theme) => {
  if (colortype === 'fixColor') {
    return fixcolor;
  } else {
    const colorId = Number(colortype.substring(1));
    return colorId < theme.length ? theme[colorId] : theme[0];
  }
};
