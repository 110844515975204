import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { isMobile } from 'react-device-detect';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
  }
}));

// ----------------------------------------------------------------------

EditorLayout.propTypes = {
  children: PropTypes.node
};

function EditorLayout({ children }) {
  const classes = useStyles();

  if (isMobile) {
    return (
      <Container sx={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Sorry, the editor is not working on small screens
        </Typography>
        <Typography color="textSecondary">
          Please open FitFace from a computer for a better experience!
        </Typography>

        <Box
          component="img"
          alt="tooSmall"
          src="/static/images/easter/tooSmall.gif"
          sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
        />

        <Button
          variant="contained"
          color="primary"
          size="large"
          component={RouterLink}
          to="/"
        >
          Go to Home
        </Button>
      </Container>
    );
  }

  return <div className={classes.root}>{children}</div>;
}

export default EditorLayout;
