import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { PATH_PAGE } from '~/routes/paths';
import LoadingScreen from '~/components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const { isLoading, logged } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!logged) {
    if (!location.pathname.endsWith('/editor/demo/')) {
      let url = PATH_PAGE.auth.login;
      if (location.pathname) {
        url += '/?redirect=' + location.pathname;
      }
      return (
        <Redirect
          to={{
            pathname: url,
            state: history.location.state
          }}
        />
      );
    }
  }

  return children;
}

export default AuthProtect;
