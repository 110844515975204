import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ROOTS } from '~/routes/paths';
import NavItem from './NavItem';
import MenuLinks from './config';
import PropTypes from 'prop-types';
import Logo from '~/components/Logo';
import Scrollbars from '~/components/Scrollbars';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import StarsIcon from '@material-ui/icons/Stars';
import {
  Box,
  List,
  Button,
  Drawer,
  Hidden,
  ListSubheader,
  Typography,
  Grid
} from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logout } from '~/redux/slices/auth';
import { isMobile } from 'react-device-detect';
import { PATH_PAGE } from '~/routes/paths';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const useStyles = makeStyles(theme => {
  const isLight = theme.palette.mode === 'light';

  return {
    drawer: {
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
      }
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter
    },
    premium: {
      paddingLeft: theme.spacing(5)
    }
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </NavItem>
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
      />
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool
};

function NavBar({ isOpenNav, onCloseNav }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isPremium, logged, username, email } = useSelector(
    state => state.auth
  );

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/');
    } catch (err) {
      console.log(err);
    }
  };

  const gallery = [
    {
      title: 'gallery',
      icon: <CollectionsIcon />,
      href: ROOTS.galleries
    }
  ];

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          {isMobile && (
            <Box sx={{ my: 2, px: 2.5 }}>
              <Typography variant="subtitle1" color="textPrimary" noWrap>
                {username}
              </Typography>
              <Typography variant="body2" color="textSecondary" noWrap>
                {email}
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>

      {!isPremium && (
        <div className={classes.premium}>
          <Button
            variant="contained"
            to={PATH_PAGE.pricing}
            startIcon={<StarsIcon />}
            component={RouterLink}
          >
            Get Premium
          </Button>
        </div>
      )}

      <List disablePadding>
        {gallery.reduce(
          (array, item) => reduceChild({ array, item, pathname, level: 0 }),
          []
        )}
      </List>
      {MenuLinks.map(list => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              className={classes.subHeader}
            >
              {list.subheader}
            </ListSubheader>
          }
        >
          {renderNavItems({
            items: list.items,
            pathname: pathname
          })}
          {isMobile && logged && list.subheader === 'general' && (
            <NavItem
              level={0}
              icon={<ExitToAppIcon />}
              title="logout"
              to=""
              onClick={handleLogout}
            />
          )}
        </List>
      ))}

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <div className={classes.doc}>
          <Box sx={{ mb: 2, typography: 'body2', color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Box>

          <Button
            fullWidth
            onClick={() => window.open('https://docs.fitface.xyz', '_blank')}
            variant="contained"
          >
            Documentation
          </Button>
        </div>
      </Box>
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;
