import jwtDecode from 'jwt-decode';

export const storeToken = token => {
  localStorage.setItem('token', token);
};

export const clearToken = () => {
  localStorage.removeItem('token');
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const headerWithToken = data => {
  return { ...data, Authorization: 'JWT ' + getToken() };
};

export const refreshTokenNeeded = () => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.exp) {
        // refresh token every 30min before expiration
        const minutesToRefresh = 30;
        const needRefresh =
          1000 * decodedToken.exp - new Date().getTime() <
          minutesToRefresh * 60 * 1000;
        return needRefresh;
      }
      return true;
    }
    // not logged
    return false;
  } catch (e) {
    return false;
  }
};
