import SvgShape from './SvgShape';
import { computeExpression, computeColor } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class Rectangle extends SvgShape {
  constructor(options) {
    super({ rCorner: 0, ...options });
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get rCorner() {
    return this.get('rCorner');
  }

  get type() {
    return 'Rectangle';
  }

  get attributes() {
    return [
      {
        name: 'x',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'y',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'width',
        dispname: 'Width',
        type: 'text'
      },
      {
        name: 'height',
        dispname: 'Height',
        type: 'text'
      },
      {
        name: 'angle',
        dispname: 'Rotation',
        type: 'text'
      },
      {
        name: 'rCorner',
        dispname: 'Radius',
        type: 'text'
      },
      {
        name: 'fill',
        dispname: 'Color',
        type: 'color',
        colortype: 'colortype',
        fixColor: 'fixColor',
        dynColor: 'dynColor'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cX = Number(computeExpression(this, 'x', data.watch));
    const cY = Number(computeExpression(this, 'y', data.watch));
    const cWidth = clampedValue(
      computeExpression(this, 'width', data.watch),
      0,
      10000
    );
    const cHeight = clampedValue(
      computeExpression(this, 'height', data.watch),
      0,
      10000
    );
    const cRCorner = Number(computeExpression(this, 'rCorner', data.watch));
    const cAngle = Number(computeExpression(this, 'angle', data.watch));
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    let cFill;
    if (this.colortype === 'dynColor') {
      cFill = computeColor(this.dynColor, data.watch);
    } else if (this.colortype === 'fixColor') {
      cFill = this.fixColor;
    } else if (this.colortype === 'inheritColor') {
      cFill = 'inherit';
    } else {
      cFill = getElementColor(this.colortype, this.fixColor, data.currentTheme);
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX,
      y: cY,
      width: cWidth,
      height: cHeight,
      rCorner: cRCorner,
      angle: cAngle,
      opacity: cOpacity,
      fill: cFill,
      bbox: {
        x: 0,
        y: 0,
        width: cWidth,
        height: cHeight,
        scale: {
          top: cHeight,
          left: cWidth
        },
        rotate: {
          top: 0,
          left: cWidth
        }
      }
    };
  }

  moveAndResize({ x, y, width, height, angle }) {
    let that = this.set('x', x);
    that = that.set('y', y);
    that = that.set('width', width);
    that = that.set('height', height);
    that = that.set('angle', angle);
    return that;
  }

  save() {
    const parentJson = super.save();
    const { width, height, rCorner } = this.toJSON();
    return { ...parentJson, width, height, rCorner };
  }
}
