import clsx from 'clsx';
import { LogoWithText } from '~/components/Logo';
import React from 'react';
import useOffSetTop from '~/hooks/useOffSetTop';
import { makeStyles } from '@material-ui/core/styles';
import { Box, AppBar, Toolbar, Container } from '@material-ui/core';
import Account from '~/layouts/Account';
import Documentation from '~/layouts/Documentation';
import CartWidget from '~/components/CartWidget';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
      height: APP_BAR_DESKTOP
    }
  },
  toolbarContainer: {
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  toolbarShadow: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    content: "''",
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.shadows[25].z8
  },
  onScroll: {
    '& $toolbar': {
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up('md')]: {
      '& $toolbar': { height: APP_BAR_DESKTOP - 20 }
    }
  }
}));

// ----------------------------------------------------------------------

function TopBar() {
  const classes = useStyles();
  const offset = useOffSetTop(100);

  return (
    <AppBar
      color="transparent"
      className={clsx(classes.root, { [classes.onScroll]: offset })}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Container maxWidth="lg" className={classes.toolbarContainer}>
          <LogoWithText />
          <Box sx={{ flexGrow: 1 }} />
          <Documentation />
          <Account />
          <CartWidget />
        </Container>
      </Toolbar>
      {offset && <span className={classes.toolbarShadow} />}
    </AppBar>
  );
}

export default TopBar;
