import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { headerWithToken } from '~/utils/tokenService';
import { pushNotification } from '~/redux/slices/notifications';
import track from '~/utils/analytics';

// ----------------------------------------------------------------------
function loadShoppingCartFromLocalStorage() {
  try {
    const cart = JSON.parse(localStorage.getItem('shoppingcart'));
    if (cart) {
      return cart.filter(function(el) {
        return el != null;
      });
    }
  } catch {
    // pass
  }
  return [];
}

const initialState = {
  cart: loadShoppingCartFromLocalStorage(),
  discounts: [],
  vat: {},
  uservat: {},

  purchaseError: '',
  purchaseClockfaceErrors: [],
  purchaseDiscountErrors: [],

  lastpurchase: null
};

export const fetchVAT = createAsyncThunk(
  'api/fetchVAT',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/payment/vat/'
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserVAT = createAsyncThunk(
  'api/fetchUserVAT',
  async (country, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/payment/vat/?country=' +
          country
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export function addToCart(watchface) {
  return dispatch => {
    // init object with only keys needed. Avoid to send datas... to localstorage
    // localstorage will complain if clockface too big...
    const w = {
      id: watchface.id,
      name: watchface.name,
      model: watchface.model,
      author: watchface.author,
      preview: watchface.preview,
      price: watchface.price,
      subscription: watchface.subscription
    };
    dispatch(__addToCart(w));
    return dispatch(
      pushNotification({
        message: watchface.name + ' added to shopping cart',
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          variant: 'success',
          autoHideDuration: 1500
        }
      })
    );
  };
}

export const __addDiscount = createAsyncThunk(
  'api/addDiscount',
  async (code, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/payment/discount/' +
          code +
          '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export function addDiscount(code) {
  return dispatch => {
    return dispatch(__addDiscount(code)).then(
      response => {
        if (!response.error) {
          if (response.payload.length > 0) {
            dispatch(
              pushNotification({
                message: 'Add discount!',
                options: {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  variant: 'success',
                  autoHideDuration: 1500
                }
              })
            );
          } else {
            dispatch(
              pushNotification({
                message: 'Invalid discount code!',
                options: {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  variant: 'warning',
                  autoHideDuration: 1500
                }
              })
            );
          }
        } else {
          if (response.payload.status === 401) {
            dispatch(
              pushNotification({
                message: 'Login to use your discount code!',
                options: {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  variant: 'info',
                  autoHideDuration: 2000
                }
              })
            );
          }
        }
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchLastPurchase = createAsyncThunk(
  'api/fetchLastPurchase',
  async (code, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/payment/last/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: 'shopping',
  initialState,
  reducers: {
    clearAllPurchaseErrors(state, action) {
      state.purchaseError = '';
      state.purchaseClockfaceErrors = [];
      state.purchaseDiscountErrors = [];
    },
    setPurchaseError(state, action) {
      state.purchaseError = action.payload;
    },
    setPurchaseClockfaceErrors(state, action) {
      state.purchaseClockfaceErrors = action.payload;
    },
    setPurchaseDiscountErrors(state, action) {
      state.purchaseDiscountErrors = action.payload;
    },
    clearCart(state, action) {
      localStorage.setItem('shoppingcart', JSON.stringify([]));
      state.cart = [];
      state.discounts = [];
    },
    __addToCart(state, action) {
      const { id, name, price } = action.payload;
      const index = state.cart.findIndex(item => id === item.id);
      if (index === -1) {
        track.event('add_to_cart', {
          items: [{ id, name, price: Number(price.amount) }]
        });
        localStorage.setItem(
          'shoppingcart',
          JSON.stringify([...state.cart, action.payload])
        );
        state.cart = [...state.cart, action.payload];
      }
    },
    removeFromCart(state, action) {
      const { id, name } = action.payload;
      track.event('remove_from_cart', {
        items: [{ id, name }]
      });
      state.cart = state.cart.filter(item => id !== item.id);
      localStorage.setItem('shoppingcart', JSON.stringify(state.cart));
    },
    setUserVATOutsideEU(state, action) {
      state.uservat = { vat: 0, country: 'Outside EU', iso_code: '' };
    },
    removeDiscount(state, action) {
      state.discounts = state.discounts.filter(
        item => action.payload !== item.id
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(__addDiscount.fulfilled, (state, action) => {
        const code = [];
        for (const i in action.payload) {
          const found = state.discounts.findIndex(
            x => x.id === action.payload[i].id
          );
          if (found < 0) {
            code.push(action.payload[i]);
          }
        }
        state.discounts = state.discounts.concat(code);
      })
      .addCase(fetchLastPurchase.fulfilled, (state, action) => {
        state.lastpurchase = action.payload;
      })
      .addCase(fetchLastPurchase.rejected, (state, action) => {
        state.lastpurchase = null;
      })
      .addCase(fetchVAT.fulfilled, (state, action) => {
        state.vat = action.payload;
      })
      .addCase(fetchVAT.rejected, (state, action) => {
        state.vat = {};
      })
      .addCase(fetchUserVAT.fulfilled, (state, action) => {
        state.uservat = action.payload;
      })
      .addCase(fetchUserVAT.rejected, (state, action) => {
        state.uservat = {};
      });
  }
});

// Reducer
export default slice.reducer;
// Actions
export const {
  clearAllPurchaseErrors,
  setPurchaseError,
  setPurchaseClockfaceErrors,
  setPurchaseDiscountErrors,
  clearCart,
  removeFromCart,
  setUserVATOutsideEU,
  removeDiscount,
  __addToCart
} = slice.actions;
