import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ROOTS } from '~/routes/paths';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import LoadingScreen from '~/components/LoadingScreen';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const { isLoading, logged } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (logged) {
    const params = new URLSearchParams(location.search);
    let redirection = params.get('redirect');
    if (!redirection) {
      const params = new URLSearchParams(location.pathname);
      redirection = params.get('redirect');
    }
    if (!redirection) {
      //wtf ??
      try {
        var query = location.pathname.split('?')[1];
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) === 'redirect') {
            redirection = decodeURIComponent(pair[1]);
          }
        }
      } catch {
        //pass
      }
    }

    if (redirection) {
      return (
        <Redirect
          to={{
            pathname: redirection,
            state: history.location.state
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: ROOTS.galleries,
            state: history.location.state
          }}
        />
      );
    }
  }

  return children;
}

export default GuestProtect;
