import { lazy } from 'react';
import GalleryLayout from '~/layouts/GalleryLayout';
import FooterLayout from '~/layouts/FooterLayout';
import { ROOTS } from './paths';

// ----------------------------------------------------------------------

export const GalleryRoutes = {
  path: ROOTS.gallery,
  layout: GalleryLayout,
  component: lazy(() => import('src/views/show/GalleryView'))
};

export const GalleryUserRoutes = {
  path: ROOTS.galleryuser,
  layout: GalleryLayout,
  component: lazy(() => import('src/views/show/GalleryView'))
};

export const GalleriesRoutes = {
  path: ROOTS.galleries,
  layout: GalleryLayout,
  component: lazy(() => import('src/views/show/GalleriesView'))
};

export const ShowcaseBundleRoutes = {
  path: ROOTS.showcasebundle,
  layout: FooterLayout,
  component: lazy(() => import('src/views/show/BundleView'))
};

export const ShowcaseRoutes = {
  path: ROOTS.showcase,
  layout: FooterLayout,
  component: lazy(() => import('src/views/show/ClockfaceView'))
};

export default GalleryRoutes;
