import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  alerts: [],
  lastfetch: null
};

export const fetchAlerts = createAsyncThunk(
  'api/fetchAlerts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/alerts/',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    closeAlert(state, action) {
      const data = JSON.parse(localStorage.getItem('alerts')) || {};
      data[action.payload.id] = Date.now();
      localStorage.setItem('alerts', JSON.stringify(data));
      state.alerts = state.alerts.filter(
        alert => alert.id !== action.payload.id
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        const alertSettings = JSON.parse(localStorage.getItem('alerts')) || {};
        const daysToExpiration = 30;
        let updateStorage = false;
        for (const key in alertSettings) {
          if (
            Date.now() - alertSettings[key] >
            daysToExpiration * 24 * 3600 * 1000
          ) {
            delete alertSettings[key];
            updateStorage = true;
          }
        }
        if (updateStorage === true) {
          localStorage.setItem('alerts', JSON.stringify(alertSettings));
        }
        state.lastfetch = Date.now();
        state.alerts = action.payload.filter(alert => {
          if (alert.id in alertSettings) {
            return false;
          } else {
            return true;
          }
        });
      })
      .addCase(fetchAlerts.rejected, (state, action) => {
        state.alerts = [];
        state.lastfetch = Date.now();
      });
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { closeAlert } = slice.actions;
