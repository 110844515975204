import { pushNotification } from '~/redux/slices/notifications';

export const apiErrorMiddleware = store => next => action => {
  if (action.type && action.type.startsWith('api')) {
    if (action.payload) {
      if (action.payload.notification) {
        // backend sent a notification, display it
        store.dispatch(
          pushNotification({
            message: action.payload.notification,
            options: {
              variant: 'error'
            }
          })
        );
        return next(action);
      }
      // backend did not sent a notification
      if (
        'errors' in action.payload &&
        typeof action.payload.errors !== 'string'
      ) {
        // fields errors is present, form will handle it, no need for notification
        return next(action);
      }

      // filter 401 error frontend should be robust to it and redirect to login page
      if (action.payload.status_code && action.payload.status_code !== 401) {
        // unknown error, server may be gone, let's display the error status
        if (action.payload.status) {
          store.dispatch(
            pushNotification({
              message: action.payload.status + ' ' + action.payload.statusText,
              options: {
                variant: 'error'
              }
            })
          );
        } else if (action.payload.status_code) {
          store.dispatch(
            pushNotification({
              message:
                action.payload.status_code + ' ' + action.payload.status_text,
              options: {
                variant: 'error'
              }
            })
          );
        }
      }
    }
  }
  return next(action);
};
