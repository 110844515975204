import Group from './Group';
import { fromJson } from './shapeBuilder';

export default class Svg extends Group {
  get resources() {
    return this.get('resources');
  }

  get fill() {
    return this.get('fill');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get origin() {
    return { x: 0, y: 0 };
  }

  get bounds() {
    const transform = 'translate(0,0)';
    return { transform };
  }

  addResource(name, data) {
    // remove the old resource with the same name if any
    const filteredResources = this.resources.filter(res => res.name !== name);
    return this.set('resources', filteredResources.push({ name, data }));
  }

  // Get the list of all the fonts used in the design
  listFonts(group) {
    let fonts = [];

    group.children.forEach(e => {
      if (e.fontFamily) {
        fonts.push(e.fontFamily);
      } else if (e.children) {
        fonts = fonts.concat(this.listFonts(e));
      }
    });

    if (group.mask) {
      fonts = fonts.concat(this.listFonts(group.mask));
    }

    return fonts;
  }

  save() {
    const { uid, width, height, fill, resources } = this.toJSON();
    const result = {
      name: this.name,
      uid,
      width,
      height,
      fill,
      resources: [],
      elements: []
    };
    this.children.forEach(element => {
      result.elements.push({ type: element.type, ...element.save() });
    });
    const fonts = this.listFonts(this);
    resources.forEach(resource => {
      // only save the fonts still used in the design
      if (fonts.indexOf(resource.name) > -1) {
        result.resources.push(resource);
      }
    });
    return result;
  }

  load(json) {
    const { name, uid, width, height, fill, elements, resources } = json;

    let that = this;
    that = that.set('uid', uid);
    that = that.set('name', name);
    that = that.set('width', width);
    that = that.set('height', height);
    that = that.set('fill', fill);
    resources &&
      resources.forEach(resource => {
        that = that.addResource(resource.name, resource.data);
      });
    elements &&
      elements.forEach(element => {
        that = that.addChild(fromJson(element));
      });
    return that;
  }
}
