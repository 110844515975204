import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import alertReducer from './slices/alert';
import darkModeReducer from './slices/dark-mode';
import modelReducer from './slices/model';
import userReducer from './slices/user';
import authReducer from './slices/auth';
import notifications from './slices/notifications';
import stripepayout from './slices/stripepayout';
import galleryReducer from './slices/gallery';
import timeReducer from './slices/time';
import watchReducer from './slices/watch';
import workspaceReducer from './slices/workspace';
import editorReducer from './slices/editor';
import collectionReducer from './slices/collection';
import shoppingReducer from './slices/shopping';

// ----------------------------------------------------------------------

export const history = createBrowserHistory();

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: ['theme', 'model']
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  notifications: notifications,
  stripepayout: stripepayout,
  auth: authReducer,
  alert: alertReducer,
  theme: darkModeReducer,
  model: modelReducer,
  user: userReducer,
  gallery: galleryReducer,
  time: timeReducer,
  watch: watchReducer,
  workspace: workspaceReducer,
  editor: editorReducer,
  collection: collectionReducer,
  shopping: shoppingReducer
});

export { rootPersistConfig, rootReducer };
