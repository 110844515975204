import SvgShape from './SvgShape';
import { computeExpression, computeColor } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class Arc extends SvgShape {
  get r() {
    return this.get('r');
  }

  get cx() {
    return this.get('cx');
  }

  get cy() {
    return this.get('cy');
  }

  get startAngle() {
    return this.get('startAngle');
  }

  get sweepAngle() {
    return this.get('sweepAngle');
  }

  get arcWidth() {
    return this.get('arcWidth');
  }

  get type() {
    return 'Arc';
  }

  get attributes() {
    return [
      {
        name: 'cx',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'cy',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'r',
        dispname: 'Radius',
        type: 'text'
      },
      {
        name: 'startAngle',
        dispname: 'Start Angle',
        type: 'text'
      },
      {
        name: 'sweepAngle',
        dispname: 'Sweep Angle',
        type: 'text'
      },
      {
        name: 'arcWidth',
        dispname: 'Arc Width',
        type: 'text'
      },
      {
        name: 'fill',
        dispname: 'Color',
        type: 'color',
        colortype: 'colortype',
        fixColor: 'fixColor',
        dynColor: 'dynColor'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cCx = Number(computeExpression(this, 'cx', data.watch));
    const cCy = Number(computeExpression(this, 'cy', data.watch));
    const cR = Number(computeExpression(this, 'r', data.watch));
    const cStartAngle = Number(
      computeExpression(this, 'startAngle', data.watch)
    );
    const cSweepAngle = Number(
      computeExpression(this, 'sweepAngle', data.watch)
    );
    let cArcWidth = clampedValue(
      computeExpression(this, 'arcWidth', data.watch),
      0,
      31
    );
    cArcWidth = clampedValue(cArcWidth, 0, cR);
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    let cFill;
    if (this.colortype === 'dynColor') {
      cFill = computeColor(this.dynColor, data.watch);
    } else if (this.colortype === 'fixColor') {
      cFill = this.fixColor;
    } else if (this.colortype === 'inheritColor') {
      cFill = 'currentColor';
    } else {
      cFill = getElementColor(this.colortype, this.fixColor, data.currentTheme);
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cCx,
      y: cCy,
      r: cR,
      opacity: cOpacity,
      fill: cFill,
      startAngle: cStartAngle,
      sweepAngle: cSweepAngle,
      arcWidth: cArcWidth,
      centered: true,
      bbox: {
        x: -cR,
        y: -cR,
        width: 2 * cR,
        height: 2 * cR,
        scale: {
          top: cR,
          left: cR
        }
      }
    };
  }

  translate(dx, dy) {
    let that = this.set('cx', dx);
    that = that.set('cy', dy);
    return that;
  }

  moveAndResize({ x, y, width, height, angle }) {
    let that = this.set('cx', x);
    that = that.set('cy', y);
    that = that.set(
      'r',
      Math.max(1, Math.round(Math.min(width / 2, height / 2)))
    );
    return that;
  }

  save() {
    const parentJson = super.save();
    parentJson.angle = undefined;
    parentJson.x = undefined;
    parentJson.y = undefined;
    const { cx, cy, r, arcWidth, startAngle, sweepAngle } = this.toJSON();
    return { ...parentJson, cx, cy, r, arcWidth, startAngle, sweepAngle };
  }
}
