import SvgShape from './SvgShape';
import { computeExpression, computeColor } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class Group extends SvgShape {
  get children() {
    return this.get('children');
  }

  get hasChildren() {
    return this.children.size > 0;
  }

  get expanded() {
    return this.get('expanded', false);
  }

  get mask() {
    return this.get('mask');
  }

  get maskactive() {
    return this.get('maskactive', true);
  }

  get type() {
    return 'Group';
  }

  get attributes() {
    return [
      {
        name: 'x',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'y',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'angle',
        dispname: 'Rotation',
        type: 'text'
      },
      {
        name: 'fill',
        dispname: 'Color',
        type: 'color',
        colortype: 'colortype',
        fixColor: 'fixColor',
        dynColor: 'dynColor'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cX = Number(computeExpression(this, 'x', data.watch));
    const cY = Number(computeExpression(this, 'y', data.watch));
    const cAngle = Number(computeExpression(this, 'angle', data.watch));
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    let cFill;
    if (this.colortype === 'dynColor') {
      cFill = computeColor(this.dynColor, data.watch);
    } else if (this.colortype === 'fixColor') {
      cFill = this.fixColor;
    } else if (this.colortype === 'inheritColor') {
      cFill = 'inherit';
    } else {
      cFill = getElementColor(this.colortype, this.fixColor, data.currentTheme);
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX,
      y: cY,
      angle: cAngle,
      opacity: cOpacity,
      fill: cFill,
      mask: this.mask && this.mask.compute(data),
      children: this.children.map(child => child.compute(data)),
      bbox: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotate: {
          top: -20,
          left: 0
        }
      }
    };
  }

  moveAndResize({ x, y, angle }) {
    let that = this.set('x', x);
    that = that.set('y', y);
    that = that.set('angle', angle);
    return that;
  }

  save() {
    const {
      uid,
      name,
      x,
      y,
      opacity,
      angle,
      colortype,
      fixColor,
      dynColor
    } = this.toJSON();
    const result = {
      name,
      uid,
      x,
      y,
      opacity,
      angle,
      colortype,
      fixColor,
      dynColor,
      elements: []
    };
    this.children.forEach(element => {
      result.elements.push({ type: element.type, ...element.save() });
    });
    if (this.mask) {
      result.mask = { type: this.mask.type, ...this.mask.save() };
    }
    return result;
  }

  import(json) {
    const {
      uid,
      name,
      x,
      y,
      opacity,
      angle,
      elements,
      colortype,
      fixColor,
      dynColor
    } = json;
    let that = this;
    that = that.set('uid', uid);
    that = that.set('name', name);
    that = that.set('x', x);
    that = that.set('y', y);
    that = that.set('opacity', opacity);
    that = that.set('angle', angle);
    that = that.set('colortype', colortype || 'fixColor');
    that = that.set('fixColor', fixColor || '#FFFFFF');
    that = that.set('dynColor', dynColor || '');
    that = that.set('expanded', elements.length === 0);
    return that;
  }

  addChild(element) {
    const newChildren = this.children.push(element);
    return this.set('children', newChildren);
  }

  removeChild(element) {
    const itemIndex = this.children.indexOf(element);
    const newChildren = this.children.delete(itemIndex);
    return this.set('children', newChildren);
  }
}
