import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const colors = {
  'color-secondary-100': '#DBECF8',
  'color-secondary-200': '#B8D8F2',
  'color-secondary-300': '#8BB1D8',
  'color-secondary-400': '#6286B1',
  'color-secondary-500': '#33527D',
  'color-secondary-600': '#253F6B',
  'color-secondary-700': '#192F59',
  'color-secondary-800': '#102048',
  'color-secondary-900': '#09163B',

  'color-primary-100': '#FFF0CC',
  'color-primary-200': '#FFDE9A',
  'color-primary-300': '#FFC667',
  'color-primary-400': '#FFAF41',
  'color-primary-500': '#FF8903',
  'color-primary-600': '#DB6B02',
  'color-primary-700': '#B75101',
  'color-primary-800': '#933A00',
  'color-primary-900': '#7A2A00',

  'color-primary-transparent-100': 'rgba(51, 82, 125, 0.08)',
  'color-primary-transparent-200': 'rgba(51, 82, 125, 0.16)',
  'color-primary-transparent-300': 'rgba(51, 82, 125, 0.24)',
  'color-primary-transparent-400': 'rgba(51, 82, 125, 0.32)',
  'color-primary-transparent-500': 'rgba(51, 82, 125, 0.4)',
  'color-primary-transparent-600': 'rgba(51, 82, 125, 0.48)',
  'color-success-100': '#E3FAD7',
  'color-success-200': '#C2F6B2',
  'color-success-300': '#94E385',
  'color-success-400': '#68C960',
  'color-success-500': '#33A535',
  'color-success-600': '#258D30',
  'color-success-700': '#19762B',
  'color-success-800': '#105F26',
  'color-success-900': '#094F23',
  'color-success-transparent-100': 'rgba(51, 165, 53, 0.08)',
  'color-success-transparent-200': 'rgba(51, 165, 53, 0.16)',
  'color-success-transparent-300': 'rgba(51, 165, 53, 0.24)',
  'color-success-transparent-400': 'rgba(51, 165, 53, 0.32)',
  'color-success-transparent-500': 'rgba(51, 165, 53, 0.4)',
  'color-success-transparent-600': 'rgba(51, 165, 53, 0.48)',
  'color-info-100': '#D4E2FD',
  'color-info-200': '#A9C3FC',
  'color-info-300': '#7EA0F8',
  'color-info-400': '#5D83F2',
  'color-info-500': '#2A57EA',
  'color-info-600': '#1E42C9',
  'color-info-700': '#1530A8',
  'color-info-800': '#0D2187',
  'color-info-900': '#081670',
  'color-info-transparent-100': 'rgba(42, 87, 234, 0.08)',
  'color-info-transparent-200': 'rgba(42, 87, 234, 0.16)',
  'color-info-transparent-300': 'rgba(42, 87, 234, 0.24)',
  'color-info-transparent-400': 'rgba(42, 87, 234, 0.32)',
  'color-info-transparent-500': 'rgba(42, 87, 234, 0.4)',
  'color-info-transparent-600': 'rgba(42, 87, 234, 0.48)',
  'color-warning-100': '#FDF4CA',
  'color-warning-200': '#FBE796',
  'color-warning-300': '#F4D361',
  'color-warning-400': '#E9BC3A',
  'color-warning-500': '#DB9D00',
  'color-warning-600': '#BC8100',
  'color-warning-700': '#9D6700',
  'color-warning-800': '#7F4F00',
  'color-warning-900': '#693F00',
  'color-warning-transparent-100': 'rgba(219, 157, 0, 0.08)',
  'color-warning-transparent-200': 'rgba(219, 157, 0, 0.16)',
  'color-warning-transparent-300': 'rgba(219, 157, 0, 0.24)',
  'color-warning-transparent-400': 'rgba(219, 157, 0, 0.32)',
  'color-warning-transparent-500': 'rgba(219, 157, 0, 0.4)',
  'color-warning-transparent-600': 'rgba(219, 157, 0, 0.48)',
  'color-danger-100': '#FBE3CD',
  'color-danger-200': '#F8C19E',
  'color-danger-300': '#EC946B',
  'color-danger-400': '#D96844',
  'color-danger-500': '#C12E11',
  'color-danger-600': '#A5190C',
  'color-danger-700': '#8A0A08',
  'color-danger-800': '#6F050C',
  'color-danger-900': '#5C030F',
  'color-danger-transparent-100': 'rgba(193, 46, 17, 0.08)',
  'color-danger-transparent-200': 'rgba(193, 46, 17, 0.16)',
  'color-danger-transparent-300': 'rgba(193, 46, 17, 0.24)',
  'color-danger-transparent-400': 'rgba(193, 46, 17, 0.32)',
  'color-danger-transparent-500': 'rgba(193, 46, 17, 0.4)',
  'color-danger-transparent-600': 'rgba(193, 46, 17, 0.48)'
};

// Setup Colors
const PRIMARY = {
  lighter: colors['color-primary-100'],
  light: colors['color-primary-300'],
  main: colors['color-primary-500'],
  dark: colors['color-primary-700'],
  darker: colors['color-primary-900']
};
const SECONDARY = {
  lighter: colors['color-secondary-100'],
  light: colors['color-secondary-300'],
  main: colors['color-secondary-500'],
  dark: colors['color-secondary-700'],
  darker: colors['color-secondary-900']
};
const INFO = {
  lighter: colors['color-info-100'],
  light: colors['color-info-300'],
  main: colors['color-info-500'],
  dark: colors['color-info-700'],
  darker: colors['color-info-900']
};
const SUCCESS = {
  lighter: colors['color-success-100'],
  light: colors['color-success-300'],
  main: colors['color-success-500'],
  dark: colors['color-success-700'],
  darker: colors['color-success-900']
};
const WARNING = {
  lighter: colors['color-warning-100'],
  light: colors['color-warning-300'],
  main: colors['color-warning-500'],
  dark: colors['color-warning-700'],
  darker: colors['color-warning-900']
};
const ERROR = {
  lighter: colors['color-danger-100'],
  light: colors['color-danger-300'],
  main: colors['color-danger-500'],
  dark: colors['color-danger-700'],
  darker: colors['color-danger-900']
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const Palette = {
  // LIGHT
  light: {
    mode: 'light',
    primary: {
      lighter: PRIMARY.lighter,
      light: PRIMARY.light,
      main: PRIMARY.main,
      dark: PRIMARY.dark,
      darker: PRIMARY.darker,
      contrastText: '#fff'
    },
    secondary: {
      lighter: SECONDARY.lighter,
      light: SECONDARY.light,
      main: SECONDARY.main,
      dark: SECONDARY.dark,
      darker: SECONDARY.darker,
      contrastText: '#fff'
    },
    info: {
      lighter: INFO.lighter,
      light: INFO.light,
      main: INFO.main,
      dark: INFO.dark,
      darker: INFO.darker,
      contrastText: '#fff'
    },
    success: {
      lighter: SUCCESS.lighter,
      light: SUCCESS.light,
      main: SUCCESS.main,
      dark: SUCCESS.dark,
      darker: SUCCESS.darker,
      contrastText: GREY[800]
    },
    warning: {
      lighter: WARNING.lighter,
      light: WARNING.light,
      main: WARNING.main,
      dark: WARNING.dark,
      darker: WARNING.darker,
      contrastText: GREY[800]
    },
    error: {
      lighter: ERROR.lighter,
      light: ERROR.light,
      main: ERROR.main,
      dark: ERROR.dark,
      darker: ERROR.darker,
      contrastText: '#fff'
    },

    grey: GREY,
    gradients: GRADIENTS,

    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500]
    },

    divider: GREY[500_24],

    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200]
    },

    action: {
      active: GREY[600],
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48
    }
  },

  // DARK
  dark: {
    mode: 'dark',
    primary: {
      lighter: PRIMARY.lighter,
      light: PRIMARY.light,
      main: PRIMARY.main,
      dark: PRIMARY.dark,
      darker: PRIMARY.darker,
      contrastText: '#fff'
    },
    secondary: {
      lighter: SECONDARY.lighter,
      light: SECONDARY.light,
      main: SECONDARY.main,
      dark: SECONDARY.dark,
      darker: SECONDARY.darker,
      contrastText: '#fff'
    },
    info: {
      lighter: INFO.lighter,
      light: INFO.light,
      main: INFO.main,
      dark: INFO.dark,
      darker: INFO.darker,
      contrastText: '#fff'
    },
    success: {
      lighter: SUCCESS.lighter,
      light: SUCCESS.light,
      main: SUCCESS.main,
      dark: SUCCESS.dark,
      darker: SUCCESS.darker,
      contrastText: GREY[800]
    },
    warning: {
      lighter: WARNING.lighter,
      light: WARNING.light,
      main: WARNING.main,
      dark: WARNING.dark,
      darker: WARNING.darker,
      contrastText: GREY[800]
    },
    error: {
      lighter: ERROR.lighter,
      light: ERROR.light,
      main: ERROR.main,
      dark: ERROR.dark,
      darker: ERROR.darker,
      contrastText: '#fff'
    },

    grey: GREY,
    gradients: GRADIENTS,

    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600]
    },

    divider: GREY[500_24],

    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16]
    },

    action: {
      active: GREY[500],
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48
    }
  }
};

export default Palette;
