import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/core';
import { fetchAlerts, closeAlert } from '~/redux/slices/alert';

// ----------------------------------------------------------------------

function Alert() {
  const dispatch = useDispatch();
  const { alerts, lastfetch } = useSelector(state => state.alert);

  useEffect(() => {
    if (alerts.length === 0) {
      if (!lastfetch || Date.now() - lastfetch > 3600 * 1000) {
        dispatch(fetchAlerts());
      }
    }
  }, [dispatch, alerts, lastfetch]);

  const onClose = alert => {
    dispatch(closeAlert(alert));
  };

  const renderLink = alert => {
    if (alert.link) {
      return <a href={alert.link}>{alert.linkname}</a>;
    } else {
      return null;
    }
  };

  return alerts.map(alert => {
    let kind = alert.kind;
    if (alert.kind === 'danger') {
      kind = 'error';
    }
    if (alert.closeable) {
      return (
        <MuiAlert
          onClose={() => onClose(alert)}
          key={alert.id}
          severity={kind}
          variant="filled"
          sx={{ marginBottom: 1 }}
        >
          <strong>{alert.title}</strong>- {alert.message} {renderLink(alert)}
        </MuiAlert>
      );
    } else {
      return (
        <MuiAlert
          key={alert.id}
          severity={kind}
          variant="filled"
          sx={{ marginBottom: 1 }}
        >
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message} {renderLink(alert)}
        </MuiAlert>
      );
    }
  });
}

export default Alert;
