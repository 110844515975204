import countries from 'iso-3166-1-codes';
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { headerWithToken } from '~/utils/tokenService';
import { pushNotification } from '~/redux/slices/notifications';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  saving: false,
  account: null,
  country: null,
  countryspecs: null,
  kyc: {},
  kycerrors: {}
};

export const getStripeConnectAccount = createAsyncThunk(
  'api/getStripeConnectAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/stripe/connect/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStripeConnectAccount = createAsyncThunk(
  'api/deleteStripeConnectAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/user/stripe/connect/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const __saveStripeConnectAccount = createAsyncThunk(
  'api/saveStripeConnectAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/stripe/connect/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function saveStripeConnectAccount(data) {
  return dispatch => {
    return dispatch(__saveStripeConnectAccount(data)).then(
      response => {
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'Information saved!',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __addBankAccount = createAsyncThunk(
  'api/addBankAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/stripe/bankaccount/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function addBankAccount(data) {
  return dispatch => {
    return dispatch(__addBankAccount(data)).then(
      response => {
        if (!response.error) {
          dispatch(getStripeConnectAccount());
          return dispatch(
            pushNotification({
              message: 'New bank account created!',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __makeDefaultBankAccount = createAsyncThunk(
  'api/makeDefaultBankAccount',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL +
          '/api/user/stripe/bankaccount/' +
          id +
          '/',
        {},
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function makeDefaultBankAccount(id) {
  return dispatch => {
    return dispatch(__makeDefaultBankAccount(id)).then(
      response => {
        if (!response.error) {
          dispatch(getStripeConnectAccount());
          return dispatch(
            pushNotification({
              message: 'Bank account updated!',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __deleteBankAccount = createAsyncThunk(
  'api/deleteBankAccount',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL +
          '/api/user/stripe/bankaccount/' +
          id +
          '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function deleteBankAccount(id) {
  return dispatch => {
    return dispatch(__deleteBankAccount(id)).then(
      response => {
        if (!response.error) {
          dispatch(getStripeConnectAccount());
          return dispatch(
            pushNotification({
              message: 'Bank account deleted!',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const getCountrySpecs = createAsyncThunk(
  'api/getCountrySpecs',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/user/stripe/country_specs/' +
          id +
          '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserCountry = createAsyncThunk(
  'api/getUserCountry',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/stripe/country/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: 'stripepayout',
  initialState,
  reducers: {
    updateKYC(state, action) {
      state.kyc = {
        ...state.kyc,
        ...action.payload
      };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getStripeConnectAccount.pending, (state, action) => {
        state.loading = true;
        state.account = null;
      })
      .addCase(getStripeConnectAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.account = action.payload;
      })
      .addCase(getStripeConnectAccount.rejected, (state, action) => {
        state.loading = false;
        state.account = null;
      })
      .addCase(__saveStripeConnectAccount.pending, (state, action) => {
        state.saving = true;
        state.account = null;
      })
      .addCase(__saveStripeConnectAccount.fulfilled, (state, action) => {
        state.saving = false;
        state.account = action.payload;
        state.kycerrors = {};
      })
      .addCase(__saveStripeConnectAccount.rejected, (state, action) => {
        state.saving = false;
        state.account = null;
        state.kycerrors = action.payload.errors;
      })
      .addCase(getCountrySpecs.fulfilled, (state, action) => {
        state.countryspecs = action.payload;
      })
      .addCase(getCountrySpecs.rejected, (state, action) => {
        state.countryspecs = null;
      })
      .addCase(getUserCountry.fulfilled, (state, action) => {
        state.country =
          action.payload.country !== ''
            ? countries.byAlpha2().get(action.payload.country)
            : null;
      })
      .addCase(getUserCountry.rejected, (state, action) => {
        state.country = null;
      });
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { updateKYC } = slice.actions;
