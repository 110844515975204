/* eslint no-new-func: 0 */

const SunCalc = require('suncalc');

export const TAGS = [
  'ddm',
  'ddmz',
  'ddy',
  'ddww',
  'ddwww',
  'ddwwww',
  'ddw',
  'dm',
  'dmz',
  'dmmm',
  'dmmmm',
  'dyy',
  'dyyyy',
  'dy',
  'dw',

  'th',
  'th12',
  'th11',
  'th24',
  'th23',
  'thz',
  'th12z',
  'th11z',
  'th24z',
  'th23z',
  'tm',
  'tmz',
  'ts',
  'tsz',
  'ta',
  'tf',
  'trh',
  'trhs',
  'trh24',
  'trh24s',
  'trm',
  'trms',
  'trs',
  'trss',
  'tu',
  'tzo',

  'as',
  'asg',
  'ahs',
  'am',
  'amg',
  'ac',
  'acg',
  'ad',
  'adg',
  'adp',
  'adpu',
  'adkm',
  'admi',
  'ae',
  'aeg',
  'ahr',
  'arhr',

  'uw',

  'sb',
  'sbc',
  'stc',
  'sdtc',

  'wu',
  'wt',
  'wtc',
  'wtf',
  'wl',
  'wcc',
  'wct',
  'wd',
  'wcl',
  'wsr',
  'wss',
  'wmp',
  'wsrt',
  'wsst',

  'mtn'
];

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const WeatherConditions = [
  'Clear Sky',
  'Few Clouds',
  'Scattered Clouds',
  'Broken Clouds',
  'Shower Rain',
  'Rain',
  'Thunderstorm',
  'Snow',
  'Mist'
];

const dayInYear = date => {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
};

const weekInYear = date => {
  var tmp = new Date(date.getTime());
  tmp.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  tmp.setDate(tmp.getDate() + 3 - ((tmp.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(tmp.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((tmp.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

const ACTIVITY_START = 1 * 3600; // activities start at 01:00
const ACTIVITY_FULL = 21 * 3600; // activities complete at 21:00

const fakeValue = (time, multi, coeff) => {
  if (time < ACTIVITY_START) return 0;
  return (
    multi *
    Math.pow((time - ACTIVITY_START) / (ACTIVITY_FULL - ACTIVITY_START), coeff)
  );
};

const minutesToStr = (minutes, mode12h) => {
  let h = (minutes / 60) | 0;
  if (mode12h) {
    h = h % 12 || 12;
  }
  return h + ':' + ('0' + (minutes % 60)).slice(-2);
};

function computeTag(
  tag,
  { date, mode12h, tapcount, doubletapcount, weather, themeId }
) {
  const secondsInDay =
    date.getSeconds() + 60 * (date.getMinutes() + 60 * date.getHours());
  const temp = 10 - 25 * Math.cos((2 * Math.PI * secondsInDay) / 86400);
  switch (tag) {
    case 'ddm':
      return date.getDate();
    case 'ddmz':
      return ('0' + date.getDate()).slice(-2);
    case 'ddy':
      return dayInYear(date);
    case 'ddww':
      return DAYS[date.getDay()].substring(0, 2);
    case 'ddwww':
      return DAYS[date.getDay()].substring(0, 3);
    case 'ddwwww':
      return DAYS[date.getDay()];
    case 'ddw':
      return date.getDay();
    case 'dm':
      return date.getMonth() + 1;
    case 'dmz':
      return ('0' + (date.getMonth() + 1)).slice(-2);
    case 'dmmm':
      return MONTHS[date.getMonth()].substring(0, 3);
    case 'dmmmm':
      return MONTHS[date.getMonth()];
    case 'dyy':
      return `${date.getFullYear()}`.slice(-2);
    case 'dyyyy':
      return date.getFullYear();
    case 'dy':
      return date.getFullYear();
    case 'dw':
      return weekInYear(date);

    case 'th':
      return mode12h ? date.getHours() % 12 || 12 : date.getHours();
    case 'th12':
      return date.getHours() % 12 || 12;
    case 'th11':
      return date.getHours() % 12;
    case 'th24':
      return date.getHours() || 24;
    case 'th23':
      return date.getHours();
    case 'thz':
      return (
        '0' + (mode12h ? date.getHours() % 12 || 12 : date.getHours())
      ).slice(-2);
    case 'th12z':
      return ('0' + (date.getHours() % 12 || 12)).slice(-2);
    case 'th11z':
      return ('0' + (date.getHours() % 12)).slice(-2);
    case 'th24z':
      return ('0' + (date.getHours() || 24)).slice(-2);
    case 'th23z':
      return ('0' + date.getHours()).slice(-2);
    case 'tm':
      return date.getMinutes();
    case 'tmz':
      return ('0' + date.getMinutes()).slice(-2);
    case 'ts':
      return date.getSeconds();
    case 'tsz':
      return ('0' + date.getSeconds()).slice(-2);
    case 'ta':
      return date.getHours() < 12 ? 'Am' : 'Pm';
    case 'tf':
      return mode12h ? 0 : 1;
    case 'trh':
      return (date.getHours() % 12) * 30;
    case 'trhs':
      return ((date.getHours() % 12) * 60 + date.getMinutes()) / 2;
    case 'trh24':
      return date.getHours() * 15;
    case 'trh24s':
      return (date.getHours() * 60 + date.getMinutes()) / 4;
    case 'trm':
      return date.getMinutes() * 6;
    case 'trms':
      return (date.getMinutes() * 60 + date.getSeconds()) / 10;
    case 'trs':
      return date.getSeconds() * 6;
    case 'trss':
      return ((date.getSeconds() * 1000 + date.getMilliseconds()) * 6) / 1000;
    case 'tu':
      return (date.getTime() / 1000) | 0;
    case 'tzo':
      return date.getTimezoneOffset();

    case 'as':
      return (10000 * fakeValue(secondsInDay, 0.9, 1.1)) | 0;
    case 'asg':
      return 10000;
    case 'ahs': {
      if (secondsInDay < ACTIVITY_START) {
        return 0;
      } else {
        const as = (10000 * fakeValue(secondsInDay, 0.9, 1.1)) | 0;
        const as00 = (10000 * fakeValue(3600 * date.getHours(), 0.9, 1.1)) | 0;
        return as - as00;
      }
    }
    case 'am':
      return (120 * fakeValue(secondsInDay, 1, 2)) | 0;
    case 'amg':
      return 120;
    case 'ac':
      return (3000 * fakeValue(secondsInDay, 1.1, 0.7)) | 0;
    case 'acg':
      return 3000;
    case 'ad':
      return (8000 * fakeValue(secondsInDay, 1, 1.1)) | 0;
    case 'adg':
      return 8000;
    case 'adkm':
      return (computeTag('ad', { date, mode12h }) * 0.001).toFixed(2);
    case 'admi':
      return (computeTag('ad', { date, mode12h }) * 0.000621371).toFixed(2);
    case 'adp':
      return computeTag(mode12h ? 'admi' : 'adkm', { date, mode12h });
    case 'adpu':
      return mode12h ? 'Mi' : 'Km';
    case 'ae':
      return (10 * fakeValue(secondsInDay, 1, 3)) | 0;
    case 'aeg':
      return 10;
    case 'ahr':
      return 40 + (secondsInDay % 180);
    case 'arhr':
      return 60;

    case 'uw':
      return 75;

    case 'sb':
      return (100 - 100 * (secondsInDay / 24 / 3600)) | 0;
    case 'sbc':
      return date.getHours() > 22 ? 1 : 0;
    case 'stc':
      return tapcount;
    case 'sdtc':
      return doubletapcount;

    case 'wu':
      return mode12h ? 'F' : 'C';
    case 'wt':
      return Math.round(mode12h ? (temp * 9) / 5 + 32 : temp);
    case 'wtc':
      return Math.round(temp);
    case 'wtf':
      return Math.round((temp * 9) / 5 + 32);
    case 'wl':
      return 'Toulouse';
    case 'wcc':
      return weather;
    case 'wct':
      return 'Sunny';
    case 'wd': {
      const curTime = date.getHours() * 60 + date.getMinutes();
      return computeTag('wsr', { date }) <= curTime &&
        curTime < computeTag('wss', { date })
        ? 1
        : 0;
    }
    case 'wcl':
      return String.fromCharCode(
        (computeTag('wd', { date }) ? 65 : 97) + weather
      );
    case 'wsr': {
      const times = SunCalc.getTimes(date, 43.6, 0);
      const currentDateTimeCentralTimeZone = new Date(
        times.sunrise.toLocaleString('en-US', { timeZone: 'Europe/Paris' })
      );
      return (
        currentDateTimeCentralTimeZone.getHours() * 60 +
        currentDateTimeCentralTimeZone.getMinutes()
      );
    }
    case 'wss': {
      const times = SunCalc.getTimes(date, 43.6, 0);
      const currentDateTimeCentralTimeZone = new Date(
        times.sunset.toLocaleString('en-US', { timeZone: 'Europe/Paris' })
      );
      return (
        currentDateTimeCentralTimeZone.getHours() * 60 +
        currentDateTimeCentralTimeZone.getMinutes()
      );
    }
    case 'wsrt': {
      return minutesToStr(computeTag('wsr', { date }), mode12h);
    }
    case 'wsst': {
      return minutesToStr(computeTag('wss', { date }), mode12h);
    }
    case 'wmp':
      return (
        Math.floor(
          ((((date.getTime() / 1000) | 0) - 592500) / 86400) % 29.5306
        ) % 30
      );

    case 'mtn':
      return themeId;
    default:
      return 0;
  }
}

const createfunc = expr => {
  try {
    return new Function(
      'cos',
      'sin',
      'tan',
      'PI',
      'rand',
      'abs',
      'round',
      'ceil',
      'floor',
      'chr',
      '__params__',
      'return ' + expr
    );
  } catch (e) {
    console.error('Invalid expression ' + expr);
    return expr;
  }
};

const PROPS_WITH_PERCENT_WIDTH = ['x', 'cx', 'x1', 'x2', 'width'];
const PROPS_WITH_PERCENT_HEIGHT = ['y', 'cy', 'y1', 'y2', 'height'];
const PROPS_WITH_PERCENT_MIN_W_H = ['r', 'arcWidth'];

const compile = (prop, value, watch) => {
  let expression = value;

  const evaluate =
    expression.length > 0 &&
    expression[0] === '(' &&
    expression[expression.length - 1] === ')';

  let percentages = expression.match(/\d+%/g);
  if (percentages) {
    percentages = new Set(percentages);
    percentages.forEach(p => {
      const regex = new RegExp(p, 'g');
      let v;
      if (PROPS_WITH_PERCENT_WIDTH.indexOf(prop) > -1) {
        v = watch.width;
      } else if (PROPS_WITH_PERCENT_HEIGHT.indexOf(prop) > -1) {
        v = watch.height;
      } else if (PROPS_WITH_PERCENT_MIN_W_H.indexOf(prop) > -1) {
        v = Math.min(watch.width, watch.height);
      }
      if (v !== undefined) {
        v = ((Number(p.substring(0, p.length - 1)) * v) / 100) | 0;
        expression = expression.replace(regex, v);
      }
    });
  }

  const tags = [];
  TAGS.forEach(tag => {
    if (expression.indexOf('#' + tag + '#') > -1) {
      if (evaluate) {
        expression = expression
          .split('#' + tag + '#')
          .join('__params__.' + tag);
      }
      tags.push(tag);
    }
  });

  if (evaluate) {
    expression = createfunc(expression);
  }
  return { expression, tags };
};

const cosd = s => Math.cos((s * Math.PI) / 180);
const sind = s => Math.sin((s * Math.PI) / 180);
const tand = s => Math.tan((s * Math.PI) / 180);
const chr = String.fromCharCode;

export function computeExpression(element, prop, watch) {
  const value = element[prop];
  if (typeof value === 'string') {
    let { expression, tags } = compile(prop, value, watch);
    const info = {
      date: new Date(watch.time),
      mode12h: watch.mode12h,
      tapcount: watch.tapcount,
      doubletapcount: watch.doubletapcount,
      weather: watch.weather,
      themeId: watch.themeId
    };
    const params = {};
    if (typeof expression === 'function') {
      try {
        tags.forEach(tag => {
          params[tag] = computeTag(tag, info);
        });
        return expression(
          cosd,
          sind,
          tand,
          Math.PI,
          Math.random,
          Math.abs,
          Math.round,
          Math.ceil,
          Math.floor,
          chr,
          params
        );
      } catch (e) {
        console.error('Cannot evaluate : ' + e.message);
        return 0;
      }
    } else {
      tags.forEach(tag => {
        expression = expression
          .split('#' + tag + '#')
          .join(computeTag(tag, info));
      });
      return expression;
    }
  } else {
    return value;
  }
}

export function computeColor(value, watch) {
  const evaluate =
    value.length > 0 && value[0] === '(' && value[value.length - 1] === ')';
  if (evaluate) {
    let colors = value.match(/(#[0-9A-Fa-f]{6})/g);
    if (colors) {
      colors = new Set(colors);
      colors.forEach(color => {
        const regex = new RegExp(color, 'g');
        value = value.replace(regex, '"' + color + '"');
      });
    }
  }
  let color = computeExpression({ color: value }, 'color', watch);
  if (typeof color === 'number') {
    color = '#' + ('000000' + Math.round(color).toString(16)).slice(-6);
  }
  return color;
}
