import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deepIsEqual } from '~/utils';

// ----------------------------------------------------------------------

const initialState = {
  error: false,
  gallery: {
    isLoading: false,
    page: 0,
    size: 0,
    hasMore: true,
    datas: []
  },
  gcategories: {
    featured: {
      isLoading: false,
      datas: []
    },
    new: {
      isLoading: false,
      datas: []
    },
    digital: {
      isLoading: false,
      datas: []
    },
    analog: {
      isLoading: false,
      datas: []
    },
    heavy: {
      isLoading: false,
      datas: []
    },
    weather: {
      isLoading: false,
      datas: []
    }
  },
  filters: {
    model: 'Versa3',
    price: 'paid',
    category: 'featured',
    search: ''
  },
  isLoading: true,
  clockface: {}
};

export const fetchGalleryPage = createAsyncThunk(
  'api/gallery/fetchGalleryPage',
  async (params, { getState, rejectWithValue }) => {
    try {
      const { filters } = getState().gallery;
      const { author, page } = params;

      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/gallery/' +
          filters.category +
          '/?page=' +
          page +
          '&model=' +
          filters.model +
          (author ? '&author=' + author : '') +
          (filters.search ? '&search=' + filters.search : '') +
          (filters.price ? '&money=' + filters.price : '')
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClockface = createAsyncThunk(
  'api/gallery/fetchClockface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/showcase/' + id + '/'
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGalleryCategory = createAsyncThunk(
  'api/fetchGalleryCategory',
  async (params, { rejectWithValue }) => {
    try {
      const { category, model } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/gallery/category/' +
        category +
        '/';
      if (model) {
        url += '?model=' + model;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    changeFilters(state, action) {
      const newFilters = { ...state.filters, ...action.payload };
      if (!deepIsEqual(newFilters, state.filters)) {
        state.filters = newFilters;
        state.gallery.page = 0;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGalleryPage.pending, (state, action) => {
        state.gallery.isLoading = true;
      })
      .addCase(fetchGalleryPage.fulfilled, (state, action) => {
        state.gallery.isLoading = false;
        if (action.payload.page) {
          if (action.payload.page === 1) {
            state.gallery.datas = [];
          }
          state.gallery.page = action.payload.page;
          state.gallery.size = action.payload.count;
          state.gallery.hasMore = action.payload.hasNext;
          state.gallery.datas = state.gallery.datas.concat(
            action.payload.results
          );
        }
      })
      .addCase(fetchGalleryPage.rejected, (state, action) => {
        state.gallery.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchClockface.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchClockface.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clockface = action.payload;
      })
      .addCase(fetchClockface.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(fetchGalleryCategory.pending, (state, action) => {
        const g = {};
        g[action.meta.arg.category] = {
          isLoading: true,
          datas: []
        };
        state.gcategories = {
          ...state.gcategories,
          ...g
        };
      })
      .addCase(fetchGalleryCategory.fulfilled, (state, action) => {
        const g = {};
        g[action.meta.arg.category] = {
          isLoading: false,
          datas: action.payload
        };
        state.gcategories = {
          ...state.gcategories,
          ...g
        };
      })
      .addCase(fetchGalleryCategory.rejected, (state, action) => {
        const g = {};
        g[action.meta.arg.category] = {
          isLoading: false,
          datas: []
        };
        state.gcategories = {
          ...state.gcategories,
          ...g
        };
      });
  }
});

// Reducer
export default slice.reducer;

export const { changeFilters } = slice.actions;
