import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { headerWithToken } from '~/utils/tokenService';
import { pushNotification } from '~/redux/slices/notifications';

// ----------------------------------------------------------------------

export const getPageSizeUserPreference = id => {
  const pageSize = JSON.parse(localStorage.getItem('pageSize')) || {};
  if (pageSize[id]) {
    return pageSize[id];
  } else {
    return 5;
  }
};

const setPageSizeUserPreference = (id, preference) => {
  const pageSize = JSON.parse(localStorage.getItem('pageSize')) || {};
  pageSize[id] = preference;
  localStorage.setItem('pageSize', JSON.stringify(pageSize));
};

const initialState = {
  favorites: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('favorites'),
    results: []
  },
  favoritesLoading: false,
  favoritesLoaded: false,

  purchases: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('purchases'),
    results: []
  },
  purchasesLoading: false,

  bundlepurchases: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('bundlepurchases'),
    results: []
  },
  bundlepurchasesLoading: false,

  archives: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('archivedpurchases'),
    results: []
  },
  archivesLoading: false,
  archivesLoaded: false,

  shared: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('shared'),
    results: []
  },
  sharedLoading: false,
  sharedLoaded: false,
  sharedRequests: 0,
  sharedTo: []
};

export const fetchPurchases = createAsyncThunk(
  'api/fetchPurchases',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/purchase/clockface/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (model) {
        url += '&model=' + model;
      }
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBundlePurchases = createAsyncThunk(
  'api/fetchBundlePurchases',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/purchase/bundle/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFavorites = createAsyncThunk(
  'api/fetchFavorites',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/favorite/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (model) {
        url += '&model=' + model;
      }
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const __addFavorite = createAsyncThunk(
  'api/addFavorite',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/favorite/',
        { id: id },
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const __removeFavorite = createAsyncThunk(
  'api/removeFavorite',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/favorite/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function addFavorite(id) {
  return (dispatch, getState) => {
    return dispatch(__addFavorite(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Added to favorite',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().collection.favorites.page,
            pageSize: getState().collection.favorites.pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchFavorites(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export function removeFavorite(id) {
  return (dispatch, getState) => {
    return dispatch(__removeFavorite(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Remove from favorite',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().collection.favorites.page,
            pageSize: getState().collection.favorites.pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchFavorites(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __addDemo = createAsyncThunk(
  'api/addDemo',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/demo/' + id + '/',
        {},
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function addDemo(id) {
  return dispatch => {
    return dispatch(__addDemo(id)).then(
      response => {
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'You can now try this clockface for free for 15 minutes',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __fetchShared = createAsyncThunk(
  'api/fetchShared',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/shared/?page=' +
          page +
          '&page_size=' +
          pageSize +
          '&model=' +
          model +
          '&search=' +
          search,
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export function fetchShared(params) {
  return dispatch => {
    return dispatch(__fetchShared(params)).then(
      response => {
        if (!response.error) {
          return dispatch(fetchSharedRequests());
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchSharedRequests = createAsyncThunk(
  'api/fetchSharedRequests',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/shared/requests/',
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const __updateShareStatus = createAsyncThunk(
  'api/updateShareStatus',
  async (params, { rejectWithValue }) => {
    try {
      const { id, status } = params;
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/share/' + id + '/',
        { status: status },
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export function acceptShare(id) {
  return (dispatch, getState) => {
    dispatch(__updateShareStatus({ id: id, status: 1 })).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Share accepted!',
              options: {
                variant: 'success'
              }
            })
          );
          dispatch(fetchSharedRequests());
          const data = {
            page: getState().collection.shared.page,
            pageSize: getState().collection.shared.pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchShared(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export function rejectShare(id) {
  return (dispatch, getState) => {
    dispatch(__updateShareStatus({ id: id, status: 2 })).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Share rejected!',
              options: {
                variant: 'success'
              }
            })
          );
          dispatch(fetchSharedRequests());
          const data = {
            page: getState().collection.shared.page,
            pageSize: getState().collection.shared.pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchShared(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchSharedTo = createAsyncThunk(
  'api/fetchSharedTo',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/share/' + id + '/',
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const __deleteSharedTo = createAsyncThunk(
  'api/deleteSharedTo',
  async (params, { rejectWithValue }) => {
    try {
      const { id, user } = params;
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/share/' + id + '/',
        {
          headers: headerWithToken(),
          data: { user: user }
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export function deleteSharedTo(id, user) {
  return (dispatch, getState) => {
    return dispatch(__deleteSharedTo({ id: id, user: user })).then(
      response => {
        if (!response.error) {
          dispatch(fetchSharedTo(id));
          const data = {
            page: getState().collection.shared.page,
            pageSize: getState().collection.shared.pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchShared(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchArchives = createAsyncThunk(
  'api/purchase/fetchArchives',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/purchase/archive/?page=' +
          page +
          '&page_size=' +
          pageSize +
          '&model=' +
          model +
          '&search=' +
          search,
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const __archiveWatchface = createAsyncThunk(
  'api/purchase/archiveWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/purchase/archive/' + id + '/',
        {},
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const __unarchiveWatchface = createAsyncThunk(
  'api/purchase/unarchiveWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/purchase/archive/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function archiveWatchface(id) {
  return (dispatch, getState) => {
    return dispatch(__archiveWatchface(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Purchase archived!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().collection.purchases.count;
          const pageSize = getState().collection.purchases.pageSize;
          let page = getState().collection.purchases.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const data = {
            page: page,
            pageSize: pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchPurchases(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export function unarchiveWatchface(id) {
  return (dispatch, getState) => {
    return dispatch(__unarchiveWatchface(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Watchface unarchived!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().collection.archives.count;
          const pageSize = getState().collection.archives.pageSize;
          let page = getState().collection.archives.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const data = {
            page: page,
            pageSize: pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchArchives(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

const slice = createSlice({
  name: 'collection',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPurchases.pending, (state, action) => {
        state.purchasesLoading = true;
      })
      .addCase(fetchPurchases.fulfilled, (state, action) => {
        state.purchasesLoading = false;
        state.purchases = action.payload;
        setPageSizeUserPreference('purchases', action.payload.pageSize);
      })
      .addCase(fetchPurchases.rejected, (state, action) => {
        state.purchasesLoading = false;
      })
      .addCase(fetchBundlePurchases.pending, (state, action) => {
        state.bundlepurchasesLoading = true;
      })
      .addCase(fetchBundlePurchases.fulfilled, (state, action) => {
        state.bundlepurchasesLoading = false;
        state.bundlepurchases = action.payload;
        setPageSizeUserPreference('bundlepurchases', action.payload.pageSize);
      })
      .addCase(fetchBundlePurchases.rejected, (state, action) => {
        state.bundlepurchasesLoading = false;
      })
      .addCase(fetchFavorites.pending, (state, action) => {
        state.favoritesLoading = true;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.favoritesLoading = false;
        state.favorites = action.payload;
        setPageSizeUserPreference('favorites', action.payload.pageSize);
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.favoritesLoading = false;
      })
      .addCase(fetchArchives.pending, (state, action) => {
        state.archivesLoading = true;
      })
      .addCase(fetchArchives.fulfilled, (state, action) => {
        state.archivesLoading = false;
        state.archives = action.payload;
        setPageSizeUserPreference('archivedpurchases', action.payload.pageSize);
      })
      .addCase(fetchArchives.rejected, (state, action) => {
        state.archivesLoading = false;
      })
      .addCase(__fetchShared.pending, (state, action) => {
        state.sharedLoading = true;
      })
      .addCase(__fetchShared.fulfilled, (state, action) => {
        state.sharedLoading = false;
        state.shared = action.payload;
        setPageSizeUserPreference('shared', action.payload.pageSize);
      })
      .addCase(__fetchShared.rejected, (state, action) => {
        state.sharedLoading = false;
      })
      .addCase(fetchSharedRequests.fulfilled, (state, action) => {
        state.sharedRequests = action.payload.requests;
      })
      .addCase(fetchSharedRequests.rejected, (state, action) => {
        state.sharedRequests = 0;
      })
      .addCase(fetchSharedTo.fulfilled, (state, action) => {
        state.sharedTo = action.payload;
      })
      .addCase(fetchSharedTo.rejected, (state, action) => {
        state.sharedTo = [];
      });
  }
});

// Reducer
export default slice.reducer;
