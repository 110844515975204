import React from 'react';
import ThemeConfig from './theme';
import { ConnectedRouter } from 'connected-react-router';
import routes, { renderRoutes } from '~/routes';
import ScrollToTop from '~/components/ScrollToTop';
import GoogleAnalytics from '~/components/GoogleAnalytics';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from '~/components/NotistackProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { history } from '~/redux/rootReducer';
// ----------------------------------------------------------------------

//const history = createBrowserHistory();

function App() {
  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotistackProvider>
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <GoogleAnalytics />
            {renderRoutes(routes)}
          </ConnectedRouter>
        </NotistackProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}

export default App;
