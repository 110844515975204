import SvgShape from './SvgShape';
import { computeExpression, computeColor } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class Line extends SvgShape {
  get x1() {
    return this.get('x1');
  }

  get y1() {
    return this.get('y1');
  }

  get x2() {
    return this.get('x2');
  }

  get y2() {
    return this.get('y2');
  }

  get strokeWidth() {
    return this.get('strokeWidth');
  }

  get round() {
    return this.get('round');
  }

  get type() {
    return 'Line';
  }

  get attributes() {
    return [
      {
        name: 'x1',
        dispname: 'x1',
        type: 'text'
      },
      {
        name: 'y1',
        dispname: 'y1',
        type: 'text'
      },
      {
        name: 'x2',
        dispname: 'x2',
        type: 'text'
      },
      {
        name: 'y2',
        dispname: 'y2',
        type: 'text'
      },
      {
        name: 'strokeWidth',
        dispname: 'Width',
        type: 'text'
      },
      {
        name: 'round',
        dispname: 'Rounded',
        type: 'bool'
      },
      {
        name: 'fill',
        dispname: 'Color',
        type: 'color',
        colortype: 'colortype',
        fixColor: 'fixColor',
        dynColor: 'dynColor'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cX1 = Number(computeExpression(this, 'x1', data.watch));
    const cY1 = Number(computeExpression(this, 'y1', data.watch));
    const cX2 = Number(computeExpression(this, 'x2', data.watch));
    const cY2 = Number(computeExpression(this, 'y2', data.watch));
    const cStrokeWidth = clampedValue(
      computeExpression(this, 'strokeWidth', data.watch),
      0,
      32
    );
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    let cFill;
    if (this.colortype === 'dynColor') {
      cFill = computeColor(this.dynColor, data.watch);
    } else if (this.colortype === 'fixColor') {
      cFill = this.fixColor;
    } else if (this.colortype === 'inheritColor') {
      cFill = 'currentColor';
    } else {
      cFill = getElementColor(this.colortype, this.fixColor, data.currentTheme);
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX1,
      y: cY1,
      x1: cX1,
      x2: cX2,
      y1: cY1,
      y2: cY2,
      fill: cFill,
      strokeWidth: cStrokeWidth,
      opacity: cOpacity,
      round: this.round,
      bbox: {
        x: cX1 < cX2 ? 0 : cX2 - cX1,
        y: cY1 < cY2 ? 0 : cY2 - cY1,
        width: Math.abs(cX1 - cX2),
        height: Math.abs(cY1 - cY2),
        scale: {
          top: cY2 - cY1,
          left: cX2 - cX1
        }
      }
    };
  }

  translate(dx, dy) {
    return this;
  }

  moveAndResize({ x, y, width, height }) {
    let that = this.set('x1', x);
    that = that.set('x2', x + width);
    that = that.set('y1', y);
    that = that.set('y2', y + height);
    return that;
  }

  save() {
    const parentJson = super.save();
    parentJson.angle = undefined;
    parentJson.x = undefined;
    parentJson.y = undefined;
    const { x1, y1, x2, y2, strokeWidth, round } = this.toJSON();
    return { ...parentJson, x1, y1, x2, y2, strokeWidth, round };
  }
}
