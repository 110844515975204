import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Button,
  Grid,
  Divider
} from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.dark,
    borderTop: '1px solid ' + theme.palette.secondary.dark,
    padding: theme.spacing(1)
  },
  container: {
    color: theme.palette.grey['500'],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  copyright: {
    color: theme.palette.grey['500'],
    margin: theme.spacing(1)
  },
  item: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.grey['500']
  }
}));

// ----------------------------------------------------------------------

Footer.propTypes = {
  className: PropTypes.string
};

function Footer({ className }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid
          className={classes.container}
          direction="row"
          container
          justifyContent="center"
          alignItems="center"
        >
          <a
            className={classes.link}
            href="https://docs.fitface.xyz/agreements/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="small" color="inherit">
              Terms
            </Button>
          </a>
          <a
            className={classes.link}
            href="https://docs.fitface.xyz/agreements/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="small" color="inherit">
              Legal
            </Button>
          </a>
          <a
            className={classes.link}
            href="https://privacypolicies.com/privacy/view/c414516349cc2105a32db9afedc86288"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="small" color="inherit">
              Privacy
            </Button>
          </a>
          <Button
            size="small"
            color="inherit"
            onClick={() => history.push('/contact')}
          >
            Contact
          </Button>
          <a
            className={classes.link}
            href="https://docs.fitface.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="small" color="inherit">
              FAQ
            </Button>
          </a>
        </Grid>
        <Divider />
        <div className={classes.copyright}>
          <Typography variant="caption" align="center" color="inherit">
            Copyright © 2019 All Rights Reserved
            <br />
          </Typography>
          <Typography variant="caption" align="center" color="inherit">
            SystemD International LP - Company number SL034277 - 5 South
            Charlotte Street, Edinburgh EH2 4AN, Scotland, United Kingdom
          </Typography>
        </div>
        <div style={{ display: 'none' }}>
          {/*process.env.COMMITHASH.substring(0, 24)*/}
        </div>
      </Container>
    </div>
  );
}

export default Footer;
