// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const ROOTS = {
  auth: '/auth',
  docs: '/docs',
  galleries: '/galleries/',
  gallery: '/gallery/',
  galleryuser: '/gallery/user/:userid',
  showcasebundle: '/show/bundle/:id',
  showcase: '/show/:id'
};

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: '/',
  dashboard: ROOTS.app
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    register: path(ROOTS.auth, '/register'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: '/confirm/email/:email/',
    verifykey: '/confirm/email/:email/:key/',
    changePassword: '/password/reset/:key/'
  },
  code: '/buy',
  qrcode: '/buy/:code/',
  maintenance: '/maintenance',
  contact: '/contact',
  pricing: '/pricing'
};

export const PATH_APP = {
  root: '/',
  user: {
    root: '/user',
    account: '/user/account',
    dashboard: '/user/dashboard'
  },
  workspace: {
    root: '/workspace',
    clockfaces: '/workspace/clockfaces',
    bundles: '/workspace/bundles',
    bundle: '/workspace/bundle/:id',
    discounts: '/workspace/discounts'
  },
  collection: {
    root: '/collection',
    purchases: '/collection/purchases',
    favorites: '/collection/favorites',
    shared: '/collection/shared'
  },
  checkout: {
    root: '/checkout/'
  }
};
