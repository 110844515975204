import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  previousTapDate: 0,
  tapcount: 0,
  doubletapcount: 0,
  weather: 0,
  themeId: 0
};

const slice = createSlice({
  name: 'watch',
  initialState,
  reducers: {
    increaseTapCount(state) {
      if (Date.now() - state.previousTapDate < 250) {
        state.previousTapDate = 0;
        state.doubletapcount = state.doubletapcount + 1;
      } else {
        state.previousTapDate = Date.now();
      }
      state.tapcount = state.tapcount + 1;
    },
    changeWeather(state) {
      state.weather = (state.weather + 1) % 9;
    },
    changeTheme(state, data) {
      state.themeId = data.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { increaseTapCount, changeWeather, changeTheme } = slice.actions;
