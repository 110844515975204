import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { ROOTS, PATH_APP, PATH_PAGE } from '~/routes/paths';
import React, { useRef, useState } from 'react';
import PopoverMenu from '~/components/PopoverMenu';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import { MIcon } from '~/@material-extend';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  matchPath
} from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography
} from '@material-ui/core';
import { MButton, MIconButton } from '~/@material-extend';
import { logout } from '~/redux/slices/auth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import WatchIcon from '@material-ui/icons/Watch';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: <HomeIcon />,
    linkTo: '/'
  },
  {
    label: 'Workspace',
    icon: <WatchIcon />,
    linkTo: PATH_APP.workspace.clockfaces
  },
  {
    label: 'Collection',
    icon: <CollectionsBookmarkIcon />,
    linkTo: PATH_APP.collection.purchases
  },
  {
    label: 'Gallery',
    icon: <PhotoLibraryIcon />,
    linkTo: ROOTS.galleries
  },
  {
    label: 'Dashboard',
    icon: <MIcon src="/static/icons/navbar/ic_dashboard.svg" />,
    linkTo: PATH_APP.user.dashboard
  }
];

const useStyles = makeStyles(theme => ({
  menuItem: {
    ...theme.typography.body2,
    padding: theme.spacing(1, 2.5)
  },
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8)
    }
  },
  withMargins: {
    margin: theme.spacing(1, 0)
  }
}));

// ----------------------------------------------------------------------

function Account() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const { logged, username, email } = useSelector(state => state.auth);
  const { darkMode } = useSelector(state => state.theme);
  //const { cart } = useSelector(state => state.shopping);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      if (isMountedRef.current) {
        history.push('/');
        handleClose();
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  if (!logged) {
    let url = PATH_PAGE.auth.login + '?redirect=';
    if (window.location.pathname === '/') {
      url += PATH_APP.collection.purchases;
    } else {
      url += window.location.pathname;
    }
    return (
      <MButton variant={'contained'} component={RouterLink} to={url}>
        Login
      </MButton>
    );
  }

  return (
    <>
      <MIconButton
        color={darkMode ? 'inherit' : 'default'}
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <AccountCircleIcon width={20} height={20} />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <RouterLink
            to={PATH_APP.user.account}
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="subtitle1" color="textPrimary" noWrap>
              {username}
            </Typography>
            <Typography variant="body2" color="textSecondary" noWrap>
              {email}
            </Typography>
          </RouterLink>
        </Box>

        <Divider className={classes.withMargins} />

        {MENU_OPTIONS.map(option => {
          if (matchPath(pathname, { path: option.linkTo, exact: true })) {
            return null;
          }
          return (
            <MenuItem
              key={option.label}
              onClick={handleClose}
              to={option.linkTo}
              component={RouterLink}
              className={classes.menuItem}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          );
        })}

        <Divider className={classes.withMargins} />

        <MenuItem
          onClick={() => handleLogout()}
          className={clsx(classes.menuItem, classes.withMargins)}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </PopoverMenu>
    </>
  );
}

export default Account;
