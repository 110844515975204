import { lazy } from 'react';
import AuthProtect from '~/components/Auth/AuthProtect';
import EditorLayout from '~/layouts/EditorLayout';

// ----------------------------------------------------------------------

const EditorRoutes = {
  path: '/editor/:id',
  guard: AuthProtect,
  layout: EditorLayout,
  component: lazy(() => import('src/views/editor/EditorView'))
};

export default EditorRoutes;
