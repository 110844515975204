import SvgShape from './SvgShape';
import { computeExpression } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class GradientRect extends SvgShape {
  constructor(options) {
    super({ colortype1: 'fixColor', colortype2: 'fixColor', ...options });
  }

  get x() {
    return this.get('x');
  }

  get y() {
    return this.get('y');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get angle() {
    return this.get('angle');
  }

  // fixColor / dynColor / themeColor
  get colortype1() {
    return this.get('colortype1');
  }

  get fixColor1() {
    return this.get('fixColor1');
  }

  get opacity1() {
    return this.get('opacity1');
  }

  get colortype2() {
    return this.get('colortype2');
  }

  get fixColor2() {
    return this.get('fixColor2');
  }

  get opacity2() {
    return this.get('opacity2');
  }

  get gradienttype() {
    return this.get('gradienttype');
  }

  get type() {
    return 'GradientRect';
  }

  get attributes() {
    return [
      {
        name: 'x',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'y',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'width',
        dispname: 'Width',
        type: 'text'
      },
      {
        name: 'height',
        dispname: 'Height',
        type: 'text'
      },
      {
        name: 'angle',
        dispname: 'Rotation',
        type: 'text'
      },
      {
        name: 'gradienttype',
        dispname: 'Type',
        type: 'gradient'
      },
      {
        name: 'c1',
        dispname: 'Color 1',
        type: 'color',
        colortype: 'colortype1',
        fixColor: 'fixColor1'
      },
      {
        name: 'opacity1',
        dispname: 'Opacity 1',
        type: 'text'
      },
      {
        name: 'c2',
        dispname: 'Color 2',
        type: 'color',
        colortype: 'colortype2',
        fixColor: 'fixColor2'
      },
      {
        name: 'opacity2',
        dispname: 'Opacity 2',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cX = Number(computeExpression(this, 'x', data.watch));
    const cY = Number(computeExpression(this, 'y', data.watch));
    const cWidth = clampedValue(
      computeExpression(this, 'width', data.watch),
      0,
      10000
    );
    const cHeight = clampedValue(
      computeExpression(this, 'height', data.watch),
      0,
      10000
    );
    const cAngle = Number(computeExpression(this, 'angle', data.watch));
    const cOpacity1 = clampedValue(
      computeExpression(this, 'opacity1', data.watch),
      0,
      1
    );
    const cOpacity2 = clampedValue(
      computeExpression(this, 'opacity2', data.watch),
      0,
      1
    );

    let cC1;
    if (this.colortype1 === 'fixColor') {
      cC1 = this.fixColor1;
    } else if (this.colortype1 === 'inheritColor') {
      cC1 = 'currentColor';
    } else {
      cC1 = getElementColor(this.colortype1, this.fixColor1, data.currentTheme);
    }

    let cC2;
    if (this.colortype2 === 'fixColor') {
      cC2 = this.fixColor2;
    } else if (this.colortype2 === 'inheritColor') {
      cC2 = 'currentColor';
    } else {
      cC2 = getElementColor(this.colortype2, this.fixColor2, data.currentTheme);
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX,
      y: cY,
      width: cWidth,
      height: cHeight,
      angle: cAngle,
      opacity1: cOpacity1,
      opacity2: cOpacity2,
      color1: cC1,
      color2: cC2,
      gradienttype: this.gradienttype,
      bbox: {
        x: 0,
        y: 0,
        width: cWidth,
        height: cHeight,
        scale: {
          top: cHeight,
          left: cWidth
        },
        rotate: {
          top: 0,
          left: cWidth
        }
      }
    };
  }

  moveAndResize({ x, y, width, height, angle }) {
    let that = this.set('x', x);
    that = that.set('y', y);
    that = that.set('width', width);
    that = that.set('height', height);
    that = that.set('angle', angle);
    return that;
  }

  save() {
    const parentJson = super.save();
    parentJson.colortype = undefined;
    parentJson.fixColor = undefined;
    parentJson.dynColor = undefined;
    const {
      width,
      height,
      opacity1,
      opacity2,
      colortype1,
      colortype2,
      fixColor1,
      fixColor2,
      gradienttype
    } = this.toJSON();
    return {
      ...parentJson,
      width,
      height,
      opacity1,
      opacity2,
      colortype1,
      colortype2,
      fixColor1,
      fixColor2,
      gradienttype
    };
  }

  import(json) {
    const copyJson = { ...json };
    if (copyJson.colortype1 === undefined) {
      if (copyJson.themed1) {
        copyJson.colortype1 = 't0';
      } else {
        copyJson.colortype1 = 'fixColor';
        copyJson.fixColor1 = copyJson.c1;
      }
      if (copyJson.themed2) {
        copyJson.colortype2 = 't0';
      } else {
        copyJson.colortype2 = 'fixColor';
        copyJson.fixColor2 = copyJson.c2;
      }
    }
    return this.merge(copyJson);
  }
}
