import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '~/components/Auth/AuthProtect';
import DashboardLayout from '~/layouts/DashboardLayout';

// ----------------------------------------------------------------------

export const WorkspaceRoutes = {
  path: PATH_APP.workspace.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.workspace.clockfaces,
      component: lazy(() => import('src/views/workspace/ClockfaceListView'))
    },

    {
      exact: true,
      path: PATH_APP.workspace.bundles,
      component: lazy(() => import('src/views/workspace/BundleListView'))
    },

    {
      exact: true,
      path: PATH_APP.workspace.bundle,
      component: lazy(() => import('src/views/workspace/BundleView'))
    },

    {
      exact: true,
      path: PATH_APP.workspace.discounts,
      component: lazy(() => import('src/views/workspace/DiscountListView'))
    },
    {
      component: props => {
        return (
          <Redirect to={'/404?origin=' + encodeURI(props.location.pathname)} />
        );
      }
    }
  ]
};

export const CollectionRoutes = {
  path: PATH_APP.collection.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.collection.purchases,
      component: lazy(() => import('src/views/collection/PurchaseListView'))
    },
    {
      exact: true,
      path: PATH_APP.collection.favorites,
      component: lazy(() => import('src/views/collection/FavoriteListView'))
    },

    {
      exact: true,
      path: PATH_APP.collection.shared,
      component: lazy(() => import('src/views/collection/SharedListView'))
    },
    {
      component: props => {
        return (
          <Redirect to={'/404?origin=' + encodeURI(props.location.pathname)} />
        );
      }
    }
  ]
};

export const UserRoutes = {
  path: PATH_APP.user.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.user.dashboard,
      component: lazy(() => import('~/views/user/DashboardView'))
    },
    {
      exact: true,
      path: PATH_APP.user.account,
      component: lazy(() => import('~/views/user/AccountView'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};
