import React from 'react';
import { MIcon } from '~/@material-extend';
import { PATH_APP } from '~/routes/paths';
import SharedRequestBadge from '~/views/collection/SharedListView/SharedRequestBadge';

import WatchIcon from '@material-ui/icons/Watch';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CollectionsIcon from '@material-ui/icons/Collections';
// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  user: <MIcon src={path('ic_user')} />
};

export default [
  // MAIN DASHBOARD
  // ----------------------------------------------------------------------

  {
    subheader: 'workspace',
    items: [
      {
        title: 'designs',
        icon: <WatchIcon />,
        href: PATH_APP.workspace.clockfaces
      },

      {
        title: 'bundles',
        icon: <CollectionsIcon />,
        href: PATH_APP.workspace.bundles
      },

      {
        title: 'discounts',
        icon: <MonetizationOnIcon />,
        href: PATH_APP.workspace.discounts
      }
    ]
  },

  // PAGES
  // ----------------------------------------------------------------------
  {
    subheader: 'collection',
    items: [
      {
        title: 'purchases',
        icon: <ShoppingCartIcon />,
        href: PATH_APP.collection.purchases
      },
      {
        title: 'favorites',
        icon: <FavoriteIcon />,
        href: PATH_APP.collection.favorites
      },
      {
        title: 'shared',
        href: PATH_APP.collection.shared,
        icon: <ShareIcon />,
        info: <SharedRequestBadge />
      }
    ]
  },

  {
    subheader: 'general',
    items: [
      {
        title: 'settings',
        icon: ICONS.user,
        href: PATH_APP.user.account
      },
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.user.dashboard
      }
    ]
  }
];
