import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  logo: {
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    strokeWidth: '11',
    stroke: '#ff8903',
    fill: 'none',
    '&:hover': {
      '& #minutes': {
        animation: `$minutesEffect 300ms ${theme.transitions.easing.easeInOut} 20ms normal forwards`,
        animationIterationCount: 1
      },
      '& #hours': {
        animation: `$hoursEffect 500ms ${theme.transitions.easing.easeInOut} 0s normal forwards`,
        animationIterationCount: 1
      }
    }
  },
  '@keyframes minutesEffect': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(' + new Date().getMinutes() * 6 + 'deg)'
    }
  },
  '@keyframes hoursEffect': {
    '0%': {
      transform: 'rotate(90deg)'
    },
    '100%': {
      transform:
        'rotate(' +
        (new Date().getHours() * 30 + new Date().getMinutes() / 2) +
        'deg)'
    }
  }
}));

function Logo({ className, ...other }) {
  const classes = useStyles();
  return (
    <Box component="div" {...other} className={classes.logo}>
      <svg width="40" height="40" viewBox="0 0 190 190">
        <g transform="translate(95,91)">
          <g id="minutes">
            <path d="m 0,0 0,-63" />
          </g>

          <g transform="rotate(90)" id="hours">
            <path d="m 0,0 0,-38" />
          </g>
        </g>

        <g transform="translate(-19.270622,-48)">
          <path d="M 190.9995,134.09225 A 76.729164,76.729164 0 0 1 114.28272,210.82142 76.729164,76.729164 0 0 1 37.541172,134.11703 76.729164,76.729164 0 0 1 114.23317,57.3631 76.729164,76.729164 0 0 1 190.99948,134.0427" />
          <path d="M 175.22787,206.28335 A 91.458336,91.458336 0 0 1 53.313243,206.12748" />
        </g>
      </svg>
    </Box>
  );
}

export function LogoWithText() {
  return (
    <>
      <RouterLink to="/">
        <Logo sx={{ marginRight: 1 }} />
      </RouterLink>
      <RouterLink
        to="/"
        style={{ color: 'white', textDecoration: 'none', marginTop: -7 }}
      >
        <Typography variant="h4" color="inherit" noWrap>
          Fit
        </Typography>
      </RouterLink>
      <RouterLink to="/" style={{ textDecoration: 'none', marginTop: -7 }}>
        <Typography variant="h4" color="primary" noWrap>
          Face
        </Typography>
      </RouterLink>
    </>
  );
}

export default Logo;
