export const TYPES = {
  Versa3: {
    width: 336,
    height: 336,
    shape: 'squircle',
    models: ['Versa 3', 'Versa 4', 'Sense', 'Sense 2'],
    showcase: { w: 469, h: 577, x: 68, y: 121 },
    fonts: [
      'System-Light',
      'System-Regular',
      'System-Bold',
      'FBNucleon-Thin',
      'FBNucleon-Regular',
      'FBNucleon-Medium',
      'FBNucleon-Bold',
      'FBNucleon-Black',
      'FBCondensed-Regular',
      'FBRational-Regular',
      'FBRational-Bold',
      'FBRational-Black',
      'Barlow-Thin',
      'Barlow-Regular',
      'Barlow-Medium',
      'Barlow-Bold',
      'BarlowCondensed-Bold'
    ]
  },

  Versa: {
    width: 300,
    height: 300,
    shape: 'rect',
    models: ['Versa', 'Versa 2', 'Versa Lite'],
    showcase: { w: 482, h: 640, x: 94, y: 166 },
    fonts: [
      'FBNucleon-Thin',
      'FBNucleon-Regular',
      'FBNucleon-Medium',
      'FBNucleon-Bold',
      'FBNucleon-Black',
      'FBCondensed-Regular',
      'FBRational-Regular',
      'FBRational-Bold',
      'FBRational-Black',
      'Seville-Regular',
      'Seville-Italic',
      'Seville-Condensed',
      'Seville-Book',
      'Seville-Book-Italic',
      'Seville-Book-Condensed',
      'Seville-Bold',
      'Seville-Bold-Italic',
      'Seville-Bold-Condensed',
      'SevilleSharp-Regular',
      'SevilleSharp-Book',
      'SevilleSharp-Bold'
    ]
  },

  Ionic: {
    width: 348,
    height: 250,
    shape: 'rect',
    models: ['Ionic'],
    showcase: { w: 444, h: 698, x: 51, y: 204 },
    fonts: [
      'FBNucleon-Thin',
      'FBNucleon-Regular',
      'FBNucleon-Medium',
      'FBNucleon-Bold',
      'FBNucleon-Black',
      'FBCondensed-Regular',
      'FBRational-Regular',
      'FBRational-Bold',
      'FBRational-Black',
      'Seville-Regular',
      'Seville-Italic',
      'Seville-Condensed',
      'Seville-Book',
      'Seville-Book-Italic',
      'Seville-Book-Condensed',
      'Seville-Bold',
      'Seville-Bold-Italic',
      'Seville-Bold-Condensed',
      'SevilleSharp-Regular',
      'SevilleSharp-Book',
      'SevilleSharp-Bold'
    ]
  }
};

export const FITBIT_FORMATS = Object.keys(TYPES);

export const getModels = type => {
  return TYPES[type].models;
};

export const getSize = type => {
  const { width, height } = TYPES[type];
  return { width, height };
};

export const getShape = (width, height) => {
  for (var i = 0; i < FITBIT_FORMATS.length; i++) {
    const model = FITBIT_FORMATS[i];
    if (TYPES[model].width === width && TYPES[model].height === height) {
      return TYPES[model].shape;
    }
  }
  return 'rect';
};

export const getShowCase = (width, height) => {
  for (var i = 0; i < FITBIT_FORMATS.length; i++) {
    const model = FITBIT_FORMATS[i];
    if (TYPES[model].width === width && TYPES[model].height === height) {
      return TYPES[model].showcase;
    }
  }
  return undefined;
};

export const getFonts = type => {
  return TYPES[type].fonts;
};

const FITBIT_FONT_MAPPING = {
  'Colfax-Thin': 'FBNucleon-Thin',
  'Colfax-Regular': 'FBNucleon-Regular',
  'Colfax-Medium': 'FBNucleon-Medium',
  'Colfax-Light': 'FBNucleon-Thin',
  'Colfax-Bold': 'FBNucleon-Bold',
  'Colfax-Black': 'FBNucleon-Black',
  'Fabrikat-Bold': 'FBRational-Bold',
  'Fabrikat-Black': 'FBRational-Black',
  'Tungsten-Medium': 'FBCondensed-Regular'
};

export const getMappedFont = fontFamily =>
  FITBIT_FONT_MAPPING[fontFamily] || fontFamily;
