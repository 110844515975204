import {
  Circle,
  Rectangle,
  GradientRect,
  Line,
  Text,
  Arc,
  ImageShape,
  Group,
  Mask,
  ImagePicker
} from '~/units';
import { List } from 'immutable';

export const randomColor = () =>
  '#' + ('000000' + (((1 << 24) * Math.random()) | 0).toString(16)).slice(-6);

export function createCircle(x, y, r = 100, color = randomColor()) {
  return new Circle({
    name: 'Circle',
    cx: x,
    cy: y,
    r: r,
    fixColor: color,
    opacity: 1.0
  });
}

export function createRectangle(
  x,
  y,
  width = 30,
  height = 30,
  color = randomColor()
) {
  return new Rectangle({
    name: 'Rectangle',
    x: x,
    y: y,
    width: width,
    height: height,
    fixColor: color,
    angle: 0,
    opacity: 1.0
  });
}

export function createImagePicker(x, y, width = 100, height = 100) {
  return new ImagePicker({
    name: 'ImagePicker',
    x: x,
    y: y,
    width: width,
    height: height,
    angle: 0,
    opacity: 1.0
  });
}

export function createGradientRect(
  x,
  y,
  width = 30,
  height = 30,
  c1 = randomColor(),
  c2 = randomColor()
) {
  return new GradientRect({
    name: 'GradientRect',
    x: x,
    y: y,
    width: width,
    height: height,
    fixColor1: c1,
    fixColor2: c2,
    angle: 0,
    opacity1: 1.0,
    opacity2: 1.0,
    gradienttype: 'linear'
  });
}

export function createLine(
  x1,
  y1,
  x2,
  y2,
  strokeWidth = 5,
  fill = randomColor()
) {
  return new Line({
    name: 'Line',
    x1: x1,
    y1: y1,
    x2: x2,
    y2: y2,
    strokeWidth: strokeWidth,
    fixColor: fill,
    opacity: 1.0,
    round: false
  });
}

export function createArc(
  x,
  y,
  r = 60,
  color = randomColor(),
  arcWidth = 20,
  startAngle = 0,
  sweepAngle = 90
) {
  return new Arc({
    name: 'Arc',
    cx: x,
    cy: y,
    r: r,
    fixColor: color,
    arcWidth: arcWidth,
    startAngle: startAngle,
    sweepAngle: sweepAngle,
    opacity: 1.0
  });
}

export function createText(
  x,
  y,
  text = '#th#:#tmz#',
  fontFamily = 'System-Regular',
  fontSize = 30,
  color = randomColor()
) {
  return new Text({
    name: 'Text',
    x,
    y,
    fixColor: color,
    angle: 0,
    fontSize,
    fontFamily,
    text,
    opacity: 1.0,
    textAnchor: 'middle',
    textCase: 'default'
  });
}

export function createImage(
  x,
  y,
  width,
  height,
  path = '',
  color = randomColor()
) {
  return new ImageShape({
    name: 'Image',
    x,
    y,
    fixColor: color,
    angle: 0,
    opacity: 1.0,
    path: path,
    width,
    height,
    grayscale: false
  });
}

export function createIcon(x, y, width, height, path, color = randomColor()) {
  return new ImageShape({
    name: 'Image',
    x,
    y,
    fixColor: color,
    angle: 0,
    opacity: 1.0,
    path: path,
    width,
    height,
    grayscale: true
  });
}

export function createGroup(x, y) {
  return new Group({
    name: 'Group',
    x,
    y,
    angle: 0,
    opacity: 1.0,
    expanded: true,
    children: List([]),
    fixColor: randomColor()
  });
}

export function createMask() {
  return new Mask({
    name: 'Mask',
    x: 0,
    y: 0,
    angle: 0,
    opacity: 1.0,
    expanded: true,
    children: List([])
  });
}

export function duplicateShape(shape) {
  if (shape.type === 'Circle') {
    return new Circle(shape.toJSON());
  } else if (shape.type === 'Rectangle') {
    return new Rectangle(shape.toJSON());
  } else if (shape.type === 'GradientRect') {
    return new GradientRect(shape.toJSON());
  } else if (shape.type === 'Line') {
    return new Line(shape.toJSON());
  } else if (shape.type === 'Arc') {
    return new Arc(shape.toJSON());
  } else if (shape.type === 'Text') {
    return new Text(shape.toJSON());
  } else if (shape.type === 'Image') {
    return new ImageShape(shape.toJSON());
  } else if (shape.type === 'ImagePicker') {
    return new ImagePicker(shape.toJSON());
  } else if (shape.type === 'Group') {
    const shapeJSON = shape.toJSON();
    shapeJSON.children = List(shape.children.map(c => duplicateShape(c)));
    if (shape.mask) {
      shapeJSON.mask = duplicateShape(shape.mask);
    }
    return new Group(shapeJSON);
  } else if (shape.type === 'Mask') {
    const shapeJSON = shape.toJSON();
    shapeJSON.children = List(shape.children.map(c => duplicateShape(c)));
    return new Mask(shapeJSON);
  } else {
    return null;
  }
}

export function fromJson(json) {
  let element = null;
  if (json.type === 'Circle') {
    element = new Circle();
  } else if (json.type === 'Rectangle') {
    element = new Rectangle();
  } else if (json.type === 'GradientRect') {
    element = new GradientRect();
  } else if (json.type === 'Line') {
    element = new Line();
  } else if (json.type === 'Arc') {
    element = new Arc();
  } else if (json.type === 'Text') {
    element = new Text();
  } else if (json.type === 'Image') {
    element = new ImageShape();
  } else if (json.type === 'ImagePicker') {
    element = new ImagePicker();
  } else if (json.type === 'Group') {
    element = new Group({ children: List([]) });
  } else if (json.type === 'Mask') {
    element = new Mask({ children: List([]) });
  }
  if (element !== null) {
    element = element.import(json);
    if (element instanceof Group) {
      json.elements.forEach(e => {
        element = element.addChild(fromJson(e));
      });
      if (json.mask) {
        element = element.set('mask', fromJson(json.mask));
      }
    }
  }
  return element;
}
