import React from 'react';
import { useSelector } from 'react-redux';
import { MIconButton } from '~/@material-extend';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// ----------------------------------------------------------------------

function Documentation() {
  const { darkMode } = useSelector(state => state.theme);

  return (
    <MIconButton
      color={darkMode ? 'inherit' : 'default'}
      onClick={() => window.open('https://docs.fitface.xyz', '_blank')}
    >
      <HelpOutlineIcon width={20} height={20} />
    </MIconButton>
  );
}

export default Documentation;
