import React from 'react';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import shoppingCartFill from '@iconify-icons/eva/shopping-cart-fill';
import { Badge } from '@material-ui/core';
import { PATH_APP } from '~/routes/paths';
import { MIconButton } from '~/@material-extend';
// ----------------------------------------------------------------------

CartWidget.propTypes = {};

function CartWidget() {
  const { cart } = useSelector(state => state.shopping);
  const { darkMode } = useSelector(state => state.theme);

  if (cart.length > 0) {
    return (
      <MIconButton
        color={darkMode ? 'inherit' : 'default'}
        component={RouterLink}
        to={PATH_APP.checkout.root}
      >
        <Badge showZero badgeContent={cart.length} color="error" max={99}>
          <Icon icon={shoppingCartFill} width={24} height={24} />
        </Badge>
      </MIconButton>
    );
  } else {
    return null;
  }
}

export default CartWidget;
