import { Map } from 'extendable-immutable';
import * as shortid from 'shortid';

export default class SvgShape extends Map {
  constructor(options) {
    super({
      colortype: 'fixColor',
      dynColor: '',
      ...options,
      uid: shortid.generate()
    });
  }

  get uid() {
    return this.get('uid');
  }

  get name() {
    return this.get('name');
  }

  get visible() {
    return this.get('visible', true);
  }

  get locked() {
    return this.get('locked', false);
  }

  get opacity() {
    return this.get('opacity');
  }

  get x() {
    return this.get('x');
  }

  get y() {
    return this.get('y');
  }

  // fixColor / dynColor / t0 / t1 / t2 / inheritColor / ...
  get colortype() {
    return this.get('colortype');
  }

  get fixColor() {
    return this.get('fixColor');
  }

  get dynColor() {
    return this.get('dynColor');
  }

  get angle() {
    return this.get('angle');
  }

  get origin() {
    return { x: 0, y: 0 };
  }

  translate(dx, dy) {
    let that = this.set('x', dx);
    that = that.set('y', dy);
    return that;
  }

  save() {
    const {
      uid,
      name,
      x,
      y,
      opacity,
      colortype,
      fixColor,
      dynColor,
      angle
    } = this.toJSON();
    return { uid, name, x, y, opacity, colortype, fixColor, dynColor, angle };
  }

  import(json) {
    const copyJson = { ...json };
    if (copyJson.colortype === undefined) {
      if (copyJson.themed) {
        copyJson.colortype = 't0';
      } else {
        copyJson.colortype = 'fixColor';
      }
      copyJson.fixColor = copyJson.fill;
    }
    return this.merge(copyJson);
  }
}
