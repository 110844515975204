import { List } from 'immutable';
import Svg from './Svg';
import * as Models from '~/helpers/models';

export function createSvg(model) {
  const { width, height } = Models.getSize(model);
  return new Svg({
    fill: '#000000',
    children: List([]),
    width: width,
    height: height,
    resources: List([])
  });
}
