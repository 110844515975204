import SvgShape from './SvgShape';
import { computeExpression, computeColor } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class ImageShape extends SvgShape {
  constructor(options) {
    super({ ratio: 1, ...options });
  }

  get ratio() {
    return this.get('ratio');
  }

  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get path() {
    return this.get('path');
  }

  get grayscale() {
    return this.get('grayscale');
  }

  get type() {
    return 'Image';
  }

  get attributes() {
    const attr = [
      {
        name: 'x',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'y',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'angle',
        dispname: 'Rotation',
        type: 'text'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      },
      {
        name: 'path',
        dispname: 'Image',
        type: 'image'
      },
      {
        name: 'grayscale',
        dispname: 'GrayScale',
        type: 'bool'
      }
    ];

    if (this.grayscale) {
      attr.push({
        name: 'fill',
        dispname: 'Color',
        type: 'color',
        colortype: 'colortype',
        fixColor: 'fixColor',
        dynColor: 'dynColor'
      });
    }

    if (this.ratio !== 1) {
      attr.push({
        name: 'ratio',
        dispname: 'Ratio',
        type: 'ratio'
      });
    }

    return attr;
  }

  compute(data) {
    const cX = Number(computeExpression(this, 'x', data.watch));
    const cY = Number(computeExpression(this, 'y', data.watch));
    const cWidth = this.width * this.ratio || 0;
    const cHeight = this.height * this.ratio || 0;
    const cAngle = Number(computeExpression(this, 'angle', data.watch));
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    let cFill;
    if (this.colortype === 'dynColor') {
      cFill = computeColor(this.dynColor, data.watch);
    } else if (this.colortype === 'fixColor') {
      cFill = this.fixColor;
    } else if (this.colortype === 'inheritColor') {
      cFill = 'inherit';
    } else {
      cFill = getElementColor(this.colortype, this.fixColor, data.currentTheme);
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX,
      y: cY,
      width: cWidth,
      height: cHeight,
      angle: cAngle,
      opacity: cOpacity,
      fill: cFill,
      path: this.path,
      grayscale: this.grayscale,
      bbox: {
        x: -cWidth / 2,
        y: -cHeight / 2,
        width: cWidth,
        height: cHeight,
        scale: this.path.endsWith('.png')
          ? undefined
          : {
              top: cHeight / 2,
              left: cWidth / 2
            },
        rotate: {
          top: -cHeight / 2,
          left: cWidth / 2
        }
      }
    };
  }

  moveAndResize({ x, y, width, height, angle }) {
    let that = this.set('x', x);
    that = that.set('y', y);
    that = that.set(
      'ratio',
      Math.max(0.1, Math.min(width / this.width, height / this.height))
    );
    that = that.set('angle', angle);
    return that;
  }

  save() {
    const parentJson = super.save();
    const { width, height, ratio, path, grayscale } = this.toJSON();
    return { ...parentJson, width, height, ratio, path, grayscale };
  }
}
