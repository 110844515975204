import 'lazysizes';
import 'intersection-observer';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import App from './App';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './redux/store';
import LoadingScreen from '~/components/LoadingScreen';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { setupAxiosInterceptors } from '~/redux/slices/auth.js';

// ----------------------------------------------------------------------

setupAxiosInterceptors(store.dispatch);

ReactDOM.render(
  <Suspense fallback={<LoadingScreen />}>
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
serviceWorker.unregister();
