import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotification } from '~/redux/slices/notifications';

function Notifier() {
  const { enqueueSnackbar } = useSnackbar();
  const { notifications } = useSelector(state => state.notifications);
  const [displayed, setDisplayed] = useState([]);

  notifications.forEach(notification => {
    setTimeout(() => {
      // If notification already displayed, abort
      if (displayed.indexOf(notification.key) > -1) return;
      // Display notification using notistack
      enqueueSnackbar(notification.message, notification.options);
      // Add notification's key to the local state
      setDisplayed([...displayed, notification.key]);
      // Dispatch action to remove the notification from the redux store
      removeNotification(notification.key);
    }, 1);
  });
  return null;
}

export default Notifier;
