import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  getToken,
  storeToken,
  clearToken,
  headerWithToken,
  refreshTokenNeeded
} from '~/utils/tokenService';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  logged: getToken() !== null,
  email: localStorage.getItem('email'),
  username: localStorage.getItem('username') || 'Unknown',
  isPremium: JSON.parse(localStorage.getItem('isPremium'))
};

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/auth/token/obtain/',
        { email, password },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk('user/logout', async () => {
  clearToken();
});

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ old_password, new_password }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/user/change_password/',
        { old_password, new_password },
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/reset_password/',
        { email },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async (token, { rejectWithValue }) => {
    try {
      // don't use axios here to avoid axios interceptors
      const response = await fetch(
        process.env.REACT_APP_SERVER_URL + '/api/auth/token/refresh/',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        }
      );
      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      return await response.json();
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        storeToken(action.payload.token);
        state.isLoading = false;
        state.logged = true;
        state.email = action.payload.user.email;
        state.isPremium = action.payload.user.isPremium;
        localStorage.setItem('email', action.payload.user.email);
        if (action.payload.user.username) {
          state.username = action.payload.user.username;
          localStorage.setItem('username', action.payload.user.username);
        } else {
          state.username = 'Unknown';
        }
        localStorage.setItem('isPremium', action.payload.user.isPremium);
      })
      .addCase(login.rejected, (state, action) => {
        clearToken();
        state.isLoading = false;
        state.logged = false;
        state.email = '';
        state.username = '';
        state.isPremium = false;
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('isPremium');
      })

      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logged = false;
        state.email = '';
        state.username = '';
        state.isPremium = false;
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('isPremium');
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        storeToken(action.payload.token);
      })
      .addCase(refreshToken.rejected, (state, action) => {
        clearToken();
      });
  }
});

// Reducer
export default slice.reducer;

export const setupAxiosInterceptors = dispatch => {
  axios.interceptors.response.use(
    response => {
      if (refreshTokenNeeded()) {
        return dispatch(refreshToken(getToken())).then(
          response1 => {
            if (response1.error) {
              dispatch(logout());
            }
            return response;
          },
          error => {
            // Rethrow so returned Promise is rejected
            throw error;
          }
        );
      }
      return response;
    },
    err => {
      if (err.message === 'Network Error') {
        dispatch(push('/maintenance'));
      }
      if (err.response.status === 500) {
        dispatch(push('/500'));
      }
      if (err.response.status === 401) {
        dispatch(logout());
      }
      return Promise.reject(err);
    }
  );
};
