import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MLabel } from '~/@material-extend';

import { fetchSharedRequests } from '~/redux/slices/collection';

SharedRequestBadge.propTypes = {};

function SharedRequestBadge() {
  const dispatch = useDispatch();
  const { sharedRequests } = useSelector(state => state.collection);

  useEffect(() => {
    dispatch(fetchSharedRequests());
  }, [dispatch]);

  if (sharedRequests > 0) {
    return (
      <MLabel variant="filled" color="info">
        {sharedRequests}+
      </MLabel>
    );
  }
  return null;
}

export default SharedRequestBadge;
