import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  is12mode: new Date().toLocaleTimeString().endsWith('M'),
  date: new Date(),
  playing: true
};

const slice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    changeDate(state, data) {
      state.date = new Date(data.payload);
    },
    changeMode(state, data) {
      if (state.is12mode !== data.payload) state.is12mode = data.payload;
    },
    setPlaying(state, data) {
      if (state.playing !== data.payload) state.playing = data.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { changeDate, changeMode, setPlaying } = slice.actions;
