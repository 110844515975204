import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  model: 'Versa3',
  userModel: undefined
};

const slice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    changeModel(state, data) {
      state.model = state.userModel = data.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { changeModel } = slice.actions;
