import Group from './Group';

export default class Mask extends Group {
  get maskactive() {
    return this.get('maskactive', true);
  }

  get type() {
    return 'Mask';
  }

  get attributes() {
    return [];
  }

  compute(data) {
    return {
      uid: this.uid,
      x: 0,
      y: 0,
      angle: 0,
      opacity: 1,
      visible: this.visible,
      children: this.children.map(child => child.compute(data))
    };
  }
}
