import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  notifications: []
};

export const pushNotification = createAsyncThunk(
  'ENQUEUE_SNACKBAR',
  async notification => {
    return {
      key: new Date().getTime() + Math.random(),
      ...notification
    };
  }
);

export const removeNotification = createAsyncThunk(
  'REMOVE_SNACKBAR',
  async key => {
    return key;
  }
);

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(pushNotification.fulfilled, (state, action) => {
        state.notifications = [...state.notifications, { ...action.payload }];
      })
      .addCase(removeNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          notification => notification.key !== action.key
        );
      });
  }
});

// Reducer
export default slice.reducer;
