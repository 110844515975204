import SvgShape from './SvgShape';
import { computeExpression } from '~/helpers/ExpressionComputer';
import { clampedValue } from '~/helpers/utils';

export default class ImagePicker extends SvgShape {
  get width() {
    return this.get('width');
  }

  get height() {
    return this.get('height');
  }

  get type() {
    return 'ImagePicker';
  }

  get attributes() {
    return [
      {
        name: 'x',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'y',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'width',
        dispname: 'Width',
        type: 'text'
      },
      {
        name: 'height',
        dispname: 'Height',
        type: 'text'
      },
      {
        name: 'angle',
        dispname: 'Rotation',
        type: 'text'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cX = Number(computeExpression(this, 'x', data.watch));
    const cY = Number(computeExpression(this, 'y', data.watch));
    const cWidth = clampedValue(
      computeExpression(this, 'width', data.watch),
      0,
      10000
    );
    const cHeight = clampedValue(
      computeExpression(this, 'height', data.watch),
      0,
      10000
    );
    const cAngle = Number(computeExpression(this, 'angle', data.watch));
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX,
      y: cY,
      width: cWidth,
      height: cHeight,
      angle: cAngle,
      opacity: cOpacity,
      bbox: {
        x: 0,
        y: 0,
        width: cWidth,
        height: cHeight,
        scale: {
          top: cHeight,
          left: cWidth
        },
        rotate: {
          top: 0,
          left: cWidth
        }
      }
    };
  }

  moveAndResize({ x, y, width, height, angle }) {
    let that = this.set('x', x);
    that = that.set('y', y);
    that = that.set('width', width);
    that = that.set('height', height);
    that = that.set('angle', angle);
    return that;
  }

  save() {
    const parentJson = super.save();
    const { width, height } = this.toJSON();
    return { ...parentJson, width, height };
  }
}
