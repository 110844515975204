import SvgShape from './SvgShape';
import { computeExpression, computeColor } from '~/helpers/ExpressionComputer';
import { clampedValue, getElementColor } from '~/helpers/utils';

export default class Text extends SvgShape {
  constructor(options) {
    super({ textCase: 'default', letterSpacing: 0, textRadius: 0, ...options });
  }

  get text() {
    return this.get('text');
  }

  get fontSize() {
    return this.get('fontSize');
  }

  get fontFamily() {
    return this.get('fontFamily');
  }

  get textAnchor() {
    return this.get('textAnchor');
  }

  get textCase() {
    return this.get('textCase');
  }

  get letterSpacing() {
    return this.get('letterSpacing');
  }

  get textRadius() {
    return this.get('textRadius');
  }

  get type() {
    return 'Text';
  }

  get attributes() {
    return [
      {
        name: 'x',
        dispname: 'X Position',
        type: 'text'
      },
      {
        name: 'y',
        dispname: 'Y Position',
        type: 'text'
      },
      {
        name: 'text',
        dispname: 'Text',
        type: 'text'
      },
      {
        name: 'fontSize',
        dispname: 'Font Size',
        type: 'text'
      },
      {
        name: 'fontFamily',
        dispname: 'Font Family',
        type: 'font'
      },
      {
        name: 'fill',
        dispname: 'Color',
        type: 'color',
        colortype: 'colortype',
        fixColor: 'fixColor',
        dynColor: 'dynColor'
      },
      {
        name: 'textAnchor',
        dispname: 'Alignment',
        type: 'textalign'
      },
      {
        name: 'textCase',
        dispname: 'Case',
        type: 'textcase'
      },
      {
        name: 'angle',
        dispname: 'Rotation',
        type: 'text'
      },
      {
        name: 'opacity',
        dispname: 'Opacity',
        type: 'text'
      },
      {
        name: 'letterSpacing',
        dispname: 'Letter Spacing',
        type: 'text'
      },
      {
        name: 'textRadius',
        dispname: 'Radius',
        type: 'text'
      }
    ];
  }

  compute(data) {
    const cX = Number(computeExpression(this, 'x', data.watch));
    const cY = Number(computeExpression(this, 'y', data.watch));
    const cFontSize = clampedValue(
      computeExpression(this, 'fontSize', data.watch),
      0,
      1000
    );
    let cText = String(computeExpression(this, 'text', data.watch));
    switch (this.textCase) {
      case 'upper':
        cText = cText.toUpperCase();
        break;
      case 'lower':
        cText = cText.toLowerCase();
        break;
      default:
    }

    const cAngle = Number(computeExpression(this, 'angle', data.watch));
    const cOpacity = clampedValue(
      computeExpression(this, 'opacity', data.watch),
      0,
      1
    );

    let cFill;
    if (this.colortype === 'dynColor') {
      cFill = computeColor(this.dynColor, data.watch);
    } else if (this.colortype === 'fixColor') {
      cFill = this.fixColor;
    } else if (this.colortype === 'inheritColor') {
      cFill = 'inherit';
    } else {
      cFill = getElementColor(this.colortype, this.fixColor, data.currentTheme);
    }

    const cTextRadius = Number(
      computeExpression(this, 'textRadius', data.watch)
    );

    let width = 20 + (cText.length * cFontSize) / 2;
    let bboxOffsetX = 0;
    let rotateX = width;
    if (this.textAnchor === 'middle') {
      bboxOffsetX = -width / 2;
      rotateX = width / 2;
    } else if (this.textAnchor === 'end') {
      bboxOffsetX = -width;
      rotateX = -width;
    }

    return {
      uid: this.uid,
      type: this.type,
      visible: this.visible,
      locked: this.locked,
      x: cX,
      y: cY,
      angle: cAngle,
      opacity: cOpacity,
      fill: cFill,
      text: cText,
      fontSize: cFontSize,
      radius: cTextRadius,
      letterSpacing: this.letterSpacing,
      textAnchor: this.textAnchor,
      fontFamily: this.fontFamily,
      bbox: {
        x: bboxOffsetX,
        y: -cFontSize,
        width: width,
        height: cFontSize,
        scale: {
          top: -cFontSize,
          left: 0,
          orientation: 'vert'
        },
        rotate: {
          top: 0,
          left: rotateX
        }
      }
    };
  }

  moveAndResize({ x, y, width, height, angle }) {
    let that = this.set('x', x);
    that = that.set('y', y);
    that = that.set('fontSize', Math.max(height, 2));
    that = that.set('angle', angle);
    return that;
  }

  save() {
    const parentJson = super.save();
    const {
      text,
      textAnchor,
      textCase,
      fontFamily,
      fontSize,
      letterSpacing,
      textRadius
    } = this.toJSON();
    return {
      ...parentJson,
      text,
      textAnchor,
      textCase,
      fontFamily,
      fontSize,
      letterSpacing,
      textRadius
    };
  }
}
