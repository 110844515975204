import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { pushNotification } from '~/redux/slices/notifications';
import { headerWithToken } from '~/utils/tokenService';
import { logout } from '~/redux/slices/auth';
// ----------------------------------------------------------------------

const initialState = {
  profileIsLoading: false,
  profile: null,
  emailsIsLoading: false,
  emails: [],
  apikeysIsLoading: false,
  apikeys: [],

  saleshistoryIsLoading: false,
  saleshistory: {
    count: 0,
    page: 1,
    pageSize: 5,
    results: []
  },
  payoutshistoryIsLoading: false,
  payoutshistory: {
    count: 0,
    page: 1,
    pageSize: 5,
    results: []
  },
  analyticsIsLoading: false,
  analytics: {},
  analyticsSalesChartIsLoading: false,
  analyticsSalesChart: {
    date: [],
    sales: [],
    incomes: []
  },
  analyticsSalesSummaryIsLoading: false,
  analyticsSalesSummary: []
};

export const createUser = createAsyncThunk(
  'api/createUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/new/',
        data
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  'api/fetchProfile',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/me/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const __updateProfile = createAsyncThunk(
  'api/updateProfile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/me/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function updateProfile(data) {
  return dispatch => {
    return dispatch(__updateProfile(data)).then(
      response => {
        if (!response.error) {
          return dispatch(fetchProfile());
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __deleteUser = createAsyncThunk(
  'api/deleteUser',
  async (password, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/user/me/',
        {
          headers: headerWithToken(),
          data: { password: password }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function deleteUser(password) {
  return dispatch => {
    return dispatch(__deleteUser(password)).then(
      response => {
        if (!response.error) {
          return dispatch(logout());
        } else {
          return response;
        }
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchEmails = createAsyncThunk(
  'api/fetchEmails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/email/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const __addEmail = createAsyncThunk(
  'api/addEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/email/',
        { email: email },
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function addEmail(email) {
  return dispatch => {
    return dispatch(__addEmail(email)).then(
      response => {
        if (!response.error) {
          return dispatch(fetchEmails());
        }
      },
      error => {
        throw error;
      }
    );
  };
}

export const __deleteEmail = createAsyncThunk(
  'api/deleteEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/user/email/',
        {
          headers: headerWithToken(),
          data: { email: email }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function deleteEmail(email) {
  return dispatch => {
    return dispatch(__deleteEmail(email)).then(
      response => {
        if (!response.error) {
          return dispatch(fetchEmails());
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}

export const __setPrimaryEmail = createAsyncThunk(
  'api/setPrimaryEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/user/email/',
        { email: email },
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function setPrimaryEmail(email) {
  return dispatch => {
    return dispatch(__setPrimaryEmail(email)).then(
      response => {
        if (!response.error) {
          return dispatch(logout());
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}

export const fetchApiKeys = createAsyncThunk(
  'api/fetchApiKeys',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/apikey/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const __createApiKey = createAsyncThunk(
  'api/createApiKey',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/apikey/',
        {},
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function createApiKey() {
  return dispatch => {
    return dispatch(__createApiKey()).then(
      response => {
        if (!response.error) {
          return dispatch(fetchApiKeys());
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}

export const __deleteApiKey = createAsyncThunk(
  'api/deleteApiKey',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/user/apikey/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function deleteApiKey(id) {
  return dispatch => {
    return dispatch(__deleteApiKey(id)).then(
      response => {
        if (!response.error) {
          return dispatch(fetchApiKeys());
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}

export const getSalesHistory = createAsyncThunk(
  'api/getSalesHistory',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize } = params;
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/payment/history/?page=' +
          page +
          '&page_size=' +
          pageSize,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPayoutsHistory = createAsyncThunk(
  'api/getPayoutsHistory',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize } = params;
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/payout/history/?page=' +
          page +
          '&page_size=' +
          pageSize,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAnalytics = createAsyncThunk(
  'api/getAnalytics',
  async (params, { rejectWithValue }) => {
    try {
      const offset = new Date().getTimezoneOffset();
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/analytics/?tz=' + offset,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSalesChart = createAsyncThunk(
  'api/getSalesChart',
  async (scale, { rejectWithValue }) => {
    try {
      const offset = new Date().getTimezoneOffset();
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          '/api/user/analytics/sales/?scale=' +
          scale +
          '&tz=' +
          offset,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSalesSummary = createAsyncThunk(
  'api/getSalesSummary',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/user/analytics/sales/summary/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const __confirmEmail = createAsyncThunk(
  'api/confirmEmail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/email/confirm/',
        params,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function confirmEmail(email, key) {
  return dispatch => {
    return dispatch(__confirmEmail({ email: email, key: key })).then(
      response => {
        dispatch(push('/auth/login/'));
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'Email confirmed!',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __reSendConfirmatationEmail = createAsyncThunk(
  'api/reSendConfirmatationEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/user/email/resend/',
        { email: email },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function reSendConfirmatationEmail(email) {
  return dispatch => {
    return dispatch(__reSendConfirmatationEmail(email)).then(
      response => {
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'Confirmation email sent!',
              options: {
                variant: 'success'
              }
            })
          );
        } else {
          return dispatch(
            pushNotification({
              message: "Can't send email!",
              options: {
                variant: 'error'
              }
            })
          );
        }
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __contactDesigner = createAsyncThunk(
  'api/contactDesigner',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/designer/contact/',
        params,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function contactDesigner(watchface, message) {
  return dispatch => {
    return dispatch(
      __contactDesigner({ watchface: watchface, message: message })
    ).then(
      response => {
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'Message sent!',
              options: {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                variant: 'success',
                autoHideDuration: 3000
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __reportClockface = createAsyncThunk(
  'api/reportClockface',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/report/watchface/',
        params,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function reportClockface(watchface, message) {
  return dispatch => {
    return dispatch(
      __reportClockface({ watchface: watchface, message: message })
    ).then(
      response => {
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'Message sent!',
              options: {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                variant: 'success',
                autoHideDuration: 3000
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProfile.pending, (state, action) => {
        state.profileIsLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileIsLoading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.profileIsLoading = false;
        state.profile = false;
        state.error = action.error;
      })

      .addCase(__updateProfile.pending, (state, action) => {
        state.profileIsLoading = true;
      })
      .addCase(__updateProfile.fulfilled, (state, action) => {
        state.profileIsLoading = false;
        state.profile = action.payload;
      })
      .addCase(__updateProfile.rejected, (state, action) => {
        state.profileIsLoading = false;
        state.error = action.error;
      })

      .addCase(fetchEmails.pending, (state, action) => {
        state.emailsIsLoading = true;
      })
      .addCase(fetchEmails.fulfilled, (state, action) => {
        state.emailsIsLoading = false;
        state.emails = action.payload;
      })
      .addCase(fetchEmails.rejected, (state, action) => {
        state.emailsIsLoading = false;
      })

      .addCase(fetchApiKeys.pending, (state, action) => {
        state.apikeysIsLoading = true;
      })
      .addCase(fetchApiKeys.fulfilled, (state, action) => {
        state.apikeysIsLoading = false;
        state.apikeys = action.payload;
      })
      .addCase(fetchApiKeys.rejected, (state, action) => {
        state.apikeysIsLoading = false;
      })
      .addCase(__createApiKey.pending, (state, action) => {
        state.apikeysIsLoading = true;
      })
      .addCase(__createApiKey.fulfilled, (state, action) => {
        state.apikeysIsLoading = false;
      })
      .addCase(__createApiKey.rejected, (state, action) => {
        state.apikeysIsLoading = false;
      })
      .addCase(__deleteApiKey.pending, (state, action) => {
        state.apikeysIsLoading = true;
      })
      .addCase(__deleteApiKey.fulfilled, (state, action) => {
        state.apikeysIsLoading = false;
      })
      .addCase(__deleteApiKey.rejected, (state, action) => {
        state.apikeysIsLoading = false;
      })

      .addCase(getSalesHistory.pending, (state, action) => {
        state.saleshistoryIsLoading = true;
      })
      .addCase(getSalesHistory.fulfilled, (state, action) => {
        state.saleshistoryIsLoading = false;
        state.saleshistory = action.payload;
      })
      .addCase(getSalesHistory.rejected, (state, action) => {
        state.saleshistoryIsLoading = false;
      })
      .addCase(getPayoutsHistory.pending, (state, action) => {
        state.payoutshistoryIsLoading = true;
      })
      .addCase(getPayoutsHistory.fulfilled, (state, action) => {
        state.payoutshistoryIsLoading = false;
        state.payoutshistory = action.payload;
      })
      .addCase(getPayoutsHistory.rejected, (state, action) => {
        state.payoutshistoryIsLoading = false;
      })
      .addCase(getAnalytics.pending, (state, action) => {
        state.analyticsIsLoading = true;
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.analyticsIsLoading = false;
        state.analytics = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.analyticsIsLoading = false;
      })
      .addCase(getSalesChart.pending, (state, action) => {
        state.analyticsSalesChartIsLoading = true;
      })
      .addCase(getSalesChart.fulfilled, (state, action) => {
        state.analyticsSalesChartIsLoading = false;
        state.analyticsSalesChart = action.payload;
      })
      .addCase(getSalesChart.rejected, (state, action) => {
        state.analyticsSalesChartIsLoading = false;
      })
      .addCase(getSalesSummary.pending, (state, action) => {
        state.analyticsSalesSummaryIsLoading = true;
      })
      .addCase(getSalesSummary.fulfilled, (state, action) => {
        state.analyticsSalesSummaryIsLoading = false;
        state.analyticsSalesSummary = action.payload;
      })
      .addCase(getSalesSummary.rejected, (state, action) => {
        state.analyticsSalesSummaryIsLoading = false;
      });
  }
});

// Reducer
export default slice.reducer;
