import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { headerWithToken } from '~/utils/tokenService';
import { push } from 'connected-react-router';
import { pushNotification } from '~/redux/slices/notifications';
import { loadSvg, updateOriginalSvg, resetSvg } from '~/redux/slices/editor';
// ----------------------------------------------------------------------

export const getPageSizeUserPreference = id => {
  const pageSize = JSON.parse(localStorage.getItem('pageSize')) || {};
  if (pageSize[id]) {
    return pageSize[id];
  } else {
    return 5;
  }
};

const setPageSizeUserPreference = (id, preference) => {
  const pageSize = JSON.parse(localStorage.getItem('pageSize')) || {};
  pageSize[id] = preference;
  localStorage.setItem('pageSize', JSON.stringify(pageSize));
};

const initialState = {
  watchfaceSaving: false,
  watchfaceLoading: false,
  watchfaceErrors: {},

  watchfaces: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('watchfaces'),
    results: []
  },
  watchfacesLoading: false,
  watchfacesLoaded: false,

  publications: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('publications'),
    results: []
  },
  publicationsLoading: false,
  publicationsLoaded: false,

  gamCount: 0,
  gamPublications: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('gamPublications'),
    results: []
  },
  gamPublicationsLoading: false,
  gamPublicationsLoaded: false,

  bundles: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('bundles'),
    results: []
  },
  bundlesLoading: false,
  bundlesLoaded: false,
  bundleSaving: false,

  bundleLoading: false,
  bundle: null,

  discounts: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('discounts'),
    results: []
  },
  discountsLoading: false,
  discountsLoaded: false,

  archives: {
    count: 0,
    page: 1,
    pageSize: getPageSizeUserPreference('archives'),
    results: []
  },
  archivesLoading: false,
  archivesLoaded: false,

  templates: []
};

export const fetchTemplates = createAsyncThunk(
  'api/fetchTemplates',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/templates/',
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGamCount = createAsyncThunk(
  'api/fetchGamCount',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/publication/gamcount/',
        { headers: headerWithToken() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchWatchfaces = createAsyncThunk(
  'api/fetchWatchfaces',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/watchface/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (model) {
        url += '&model=' + model;
      }
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPublications = createAsyncThunk(
  'api/fetchPublications',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/publication/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (model) {
        url += '&model=' + model;
      }
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGamPublications = createAsyncThunk(
  'api/fetchGamPublications',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/publication/gam/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (model) {
        url += '&model=' + model;
      }
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBundles = createAsyncThunk(
  'api/fetchBundles',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/bundle/?page=' +
        page +
        '&page_size=' +
        pageSize;
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchBundle = createAsyncThunk(
  'api/fetchBundle',
  async (id, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_SERVER_URL + '/api/bundle/' + id + '/';
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const __createBundle = createAsyncThunk(
  'api/createBundle',
  async (name, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/bundle/',
        { name: name },
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function createBundle(name) {
  return (dispatch, getState) => {
    return dispatch(__createBundle(name)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Bundle created!',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().workspace.bundles.page,
            pageSize: getState().workspace.bundles.pageSize
          };
          dispatch(fetchBundles(data));
          return dispatch(
            push('/workspace/bundle/' + response.payload.id + '/')
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __deleteBundle = createAsyncThunk(
  'api/deleteBundle',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/bundle/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function deleteBundle(id) {
  return (dispatch, getState) => {
    return dispatch(__deleteBundle(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Bundle deleted!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().workspace.bundles.count;
          const pageSize = getState().workspace.bundles.pageSize;
          let page = getState().workspace.bundles.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const data = {
            page: page,
            pageSize: pageSize
          };
          return dispatch(fetchBundles(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __saveBundle = createAsyncThunk(
  'api/saveBundle',
  async (params, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/bundle/' + id + '/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function saveBundle(id, data) {
  return (dispatch, getState) => {
    return dispatch(__saveBundle({ id: id, data: data })).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Bundle saved!',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().workspace.bundles.page,
            pageSize: getState().workspace.bundles.pageSize
          };
          return dispatch(fetchBundles(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __fetchWatchface = createAsyncThunk(
  'api/fetchWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/api/watchface/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const __editWatchface = createAsyncThunk(
  'api/editWatchface',
  async (params, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/watchface/' + id + '/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

const WATCHFACE_DATA_KEY = '$fitFace#^';

function xorStrings(key, input) {
  var output = '';
  for (var i = 0; i < input.length; i++) {
    var c = input.charCodeAt(i);
    var k = key.charCodeAt(i % key.length);
    output += String.fromCharCode(c ^ k);
  }
  return output;
}

const B64XorCipher = {
  encode: function(key, data) {
    return Buffer.from(xorStrings(key, data), 'utf8').toString('base64');
  },
  decode: function(key, data) {
    data = Buffer.from(data, 'base64').toString('utf8');
    return xorStrings(key, data);
  }
};

export function loadWatchface(id) {
  return dispatch => {
    dispatch(resetSvg());
    return dispatch(__fetchWatchface(id)).then(
      response => {
        if (!response.error) {
          const payload = response.payload;
          if (payload.data) {
            payload.data = JSON.parse(
              B64XorCipher.decode(WATCHFACE_DATA_KEY, payload.data)
            );
          }
          if (localStorage.getItem('wip')) {
            payload.data = JSON.parse(localStorage.getItem('wip'));
            localStorage.removeItem('wip');
            dispatch(
              pushNotification({
                message:
                  'Work in progress reloaded! Make sure to save your design to avoid loosing your changes! Reload page to discard current work.',
                options: {
                  variant: 'warning'
                }
              })
            );
          }
          return dispatch(loadSvg(payload));
        } else {
          if (response.payload.status_code === 404) {
            return dispatch(push('/404'));
          } else if (response.payload.status_code === 500) {
            return dispatch(push('/500'));
          } else {
            return dispatch(push('/500'));
          }
        }
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export function saveWatchface(id, data) {
  if (data.data) {
    // encrypt watchface data
    data.data = B64XorCipher.encode(
      WATCHFACE_DATA_KEY,
      JSON.stringify(data.data)
    );
  }
  return (dispatch, getState) => {
    return dispatch(__editWatchface({ id: id, data: data })).then(
      response => {
        if (!response.error) {
          localStorage.removeItem('wip');
          dispatch(updateOriginalSvg());
          // fetch watchfaces here since we don't refresh every time the workspace
          //dispatch(fetchWatchfaces(getState().watchfaces.watchfaces.page))
          return dispatch(
            pushNotification({
              message: 'Watchface saved!',
              options: {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                variant: 'success',
                autoHideDuration: 1500
              }
            })
          );
        } else {
          if (response.payload.status === 401) {
            // should only happen when account is disabled and editor was open before
            // otherwise let the apimiddleware handling  it
            let msg = "Can't saved watchface";
            try {
              if (response.payload.response.errors) {
                msg += ': ' + response.payload.response.errors;
              } else {
                msg += ': ' + response.payload.message;
              }
            } catch {
              // pass unknown error
            }
            return dispatch(
              pushNotification({
                message: msg,
                options: {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  variant: 'error',
                  autoHideDuration: 3000
                }
              })
            );
          }
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export const __createWatchface = createAsyncThunk(
  'api/createWatchface',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/watchface/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const __deleteWatchface = createAsyncThunk(
  'api/deleteWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/watchface/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function createWatchface(name, model, template) {
  return (dispatch, getState) => {
    return dispatch(
      __createWatchface({ name: name, model: model, template: template })
    ).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Clockface created!',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().workspace.watchfaces.page,
            pageSize: getState().workspace.watchfaces.pageSize,
            model: getState().model.model
          };
          dispatch(fetchWatchfaces(data));
          //redirect to editor
          return dispatch(push('/editor/' + response.payload.id + '/'));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export function deleteWatchface(id) {
  return (dispatch, getState) => {
    return dispatch(__deleteWatchface(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Watchface deleted!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().workspace.watchfaces.count;
          const pageSize = getState().workspace.watchfaces.pageSize;
          let page = getState().workspace.watchfaces.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const data = {
            page: page,
            pageSize: pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchWatchfaces(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __publishWatchface = createAsyncThunk(
  'api/publishWatchface',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/publication/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function publishWatchface(
  id,
  description,
  price,
  currency,
  acceptCondition,
  allowGam
) {
  return (dispatch, getState) => {
    return dispatch(
      __publishWatchface({
        watchfaceid: id,
        published: true,
        description: description,
        price: { amount: price, currency: currency },
        acceptCondition: acceptCondition,
        allowGam: allowGam
      })
    ).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Watchface published!',
              options: {
                variant: 'success'
              }
            })
          );
          const wdata = {
            page: getState().workspace.watchfaces.page,
            pageSize: getState().workspace.watchfaces.pageSize,
            model: getState().model.model
          };
          dispatch(fetchWatchfaces(wdata));
          const pdata = {
            page: getState().workspace.publications.page,
            pageSize: getState().workspace.publications.pageSize,
            model: getState().model.model
          };
          return dispatch(fetchPublications(pdata));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __unpublishWatchface = createAsyncThunk(
  'api/unpublishWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/publication/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export function unpublishWatchface(id) {
  return (dispatch, getState) => {
    return dispatch(__unpublishWatchface(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Watchface unpublished!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().workspace.publications.count;
          const pageSize = getState().workspace.publications.pageSize;
          let page = getState().workspace.publications.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const pdata = {
            page: page,
            pageSize: pageSize,
            model: getState().model.model
          };
          return dispatch(fetchPublications(pdata));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchArchives = createAsyncThunk(
  'api/watchface/fetchArchives',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize, model, search } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/archive/?page=' +
        page +
        '&page_size=' +
        pageSize;
      if (model) {
        url += '&model=' + model;
      }
      if (search) {
        url += '&search=' + search;
      }
      const response = await axios.get(url, { headers: headerWithToken() });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const __archiveWatchface = createAsyncThunk(
  'api/archiveWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/archive/' + id + '/',
        {},
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const __unarchiveWatchface = createAsyncThunk(
  'api/unarchiveWatchface',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/archive/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function archiveWatchface(id) {
  return (dispatch, getState) => {
    return dispatch(__archiveWatchface(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Watchface archived!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().workspace.watchfaces.count;
          const pageSize = getState().workspace.watchfaces.pageSize;
          let page = getState().workspace.watchfaces.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const data = {
            page: page,
            pageSize: pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchWatchfaces(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}
export function unarchiveWatchface(id) {
  return (dispatch, getState) => {
    return dispatch(__unarchiveWatchface(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Watchface unarchived!',
              options: {
                variant: 'success'
              }
            })
          );
          // if the removed element is the only one on last page => fetch previous page
          const count = getState().workspace.archives.count;
          const pageSize = getState().workspace.archives.pageSize;
          let page = getState().workspace.archives.page;
          if (count % pageSize === 1) {
            page = page - 1;
            if (page <= 0) {
              page = 1;
            }
          }
          const data = {
            page: page,
            pageSize: pageSize,
            model: getState().model.model,
            search: '' //TODO
          };
          return dispatch(fetchArchives(data));
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const __shareWatchface = createAsyncThunk(
  'api/shareWatchface',
  async (params, { rejectWithValue }) => {
    try {
      const { id, user } = params;
      await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/share/' + id + '/',
        { user: user },
        { headers: headerWithToken({ 'Content-Type': 'application/json' }) }
      );
      return { id };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export function shareWatchface(id, user) {
  return dispatch => {
    return dispatch(__shareWatchface({ id: id, user: user })).then(
      response => {
        if (!response.error) {
          return dispatch(
            pushNotification({
              message: 'Watchface shared!',
              options: {
                variant: 'success'
              }
            })
          );
        }
        return response;
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error;
      }
    );
  };
}

export const fetchDiscounts = createAsyncThunk(
  'api/fetchDiscounts',
  async (params, { rejectWithValue }) => {
    try {
      const { page, pageSize } = params;
      let url =
        process.env.REACT_APP_SERVER_URL +
        '/api/discount/?page=' +
        page +
        '&page_size=' +
        pageSize;
      const response = await axios.get(url, {
        headers: headerWithToken()
      });
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const __createDiscount = createAsyncThunk(
  'api/createDiscount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/api/discount/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function createDiscount(data) {
  return (dispatch, getState) => {
    return dispatch(__createDiscount(data)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Discount created!',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().workspace.discounts.page,
            pageSize: getState().workspace.discounts.pageSize
          };
          return dispatch(fetchDiscounts(data));
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}
export const __saveDiscount = createAsyncThunk(
  'api/saveDiscount',
  async (params, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      const response = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/api/discount/' + id + '/',
        data,
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function saveDiscount(id, data) {
  return (dispatch, getState) => {
    return dispatch(__saveDiscount({ id: id, data: data })).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Discount saved!',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().workspace.discounts.page,
            pageSize: getState().workspace.discounts.pageSize
          };
          return dispatch(fetchDiscounts(data));
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}
export const __deleteDiscount = createAsyncThunk(
  'api/deleteDiscount',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL + '/api/discount/' + id + '/',
        {
          headers: headerWithToken()
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export function deleteDiscount(id) {
  return (dispatch, getState) => {
    return dispatch(__deleteDiscount(id)).then(
      response => {
        if (!response.error) {
          dispatch(
            pushNotification({
              message: 'Discount deleted!',
              options: {
                variant: 'success'
              }
            })
          );
          const data = {
            page: getState().workspace.discounts.page,
            pageSize: getState().workspace.discounts.pageSize
          };
          return dispatch(fetchDiscounts(data));
        }
        return response;
      },
      error => {
        throw error;
      }
    );
  };
}

const slice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templates = action.payload;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.templates = [];
      })
      .addCase(fetchGamCount.fulfilled, (state, action) => {
        state.gamCount = action.payload.count;
      })
      .addCase(fetchGamCount.rejected, (state, action) => {
        state.gamCount = 0;
      })
      .addCase(fetchWatchfaces.pending, (state, action) => {
        state.watchfacesLoading = true;
      })
      .addCase(fetchWatchfaces.fulfilled, (state, action) => {
        state.watchfacesLoading = false;
        state.watchfaces = action.payload;
        setPageSizeUserPreference('watchfaces', action.payload.pageSize);
      })
      .addCase(fetchWatchfaces.rejected, (state, action) => {
        state.watchfacesLoading = false;
      })
      .addCase(fetchPublications.pending, (state, action) => {
        state.publicationsLoading = true;
      })
      .addCase(fetchPublications.fulfilled, (state, action) => {
        state.publicationsLoading = false;
        state.publications = action.payload;
        setPageSizeUserPreference('publications', action.payload.pageSize);
      })
      .addCase(fetchPublications.rejected, (state, action) => {
        state.publicationsLoading = false;
      })
      .addCase(fetchGamPublications.pending, (state, action) => {
        state.gamPublicationsLoading = true;
      })
      .addCase(fetchGamPublications.fulfilled, (state, action) => {
        state.gamPublicationsLoading = false;
        state.gamPublications = action.payload;
        setPageSizeUserPreference('gamPublications', action.payload.pageSize);
      })
      .addCase(fetchGamPublications.rejected, (state, action) => {
        state.gamPublicationsLoading = false;
      })
      .addCase(fetchBundles.pending, (state, action) => {
        state.bundlesLoading = true;
      })
      .addCase(fetchBundles.fulfilled, (state, action) => {
        state.bundlesLoading = false;
        state.bundles = action.payload;
        setPageSizeUserPreference('bundles', action.payload.pageSize);
      })
      .addCase(fetchBundles.rejected, (state, action) => {
        state.bundlesLoading = false;
      })
      .addCase(fetchBundle.pending, (state, action) => {
        state.bundleLoading = true;
      })
      .addCase(fetchBundle.fulfilled, (state, action) => {
        state.bundleLoading = false;
        state.bundle = action.payload;
      })
      .addCase(fetchBundle.rejected, (state, action) => {
        state.bundle = null;
        state.bundleLoading = false;
      })
      .addCase(fetchArchives.pending, (state, action) => {
        state.archivesLoading = true;
      })
      .addCase(fetchArchives.fulfilled, (state, action) => {
        state.archivesLoading = false;
        state.archives = action.payload;
        setPageSizeUserPreference('archives', action.payload.pageSize);
      })
      .addCase(fetchArchives.rejected, (state, action) => {
        state.archivesLoading = false;
      })
      .addCase(fetchDiscounts.pending, (state, action) => {
        state.discountsLoading = true;
      })
      .addCase(fetchDiscounts.fulfilled, (state, action) => {
        state.discountsLoading = false;
        state.discounts = action.payload;
        setPageSizeUserPreference('discounts', action.payload.pageSize);
      })
      .addCase(fetchDiscounts.rejected, (state, action) => {
        state.discountsLoading = false;
      })
      .addCase(__editWatchface.pending, (state, action) => {
        state.watchfaceSaving = true;
      })
      .addCase(__editWatchface.fulfilled, (state, action) => {
        state.watchfaceSaving = false;
        state.watchfaceErrors = {};
      })
      .addCase(__editWatchface.rejected, (state, action) => {
        state.watchfaceSaving = false;
        state.watchfaceErrors = action.payload.errors;
      })
      .addCase(__fetchWatchface.pending, (state, action) => {
        state.watchfaceErrors = {};
        state.watchfaceLoading = true;
      })
      .addCase(__fetchWatchface.fulfilled, (state, action) => {
        state.watchfaceLoading = false;
      })
      .addCase(__fetchWatchface.rejected, (state, action) => {
        state.watchfaceLoading = false;
      });
  }
});

// Reducer
export default slice.reducer;
